.card-suppliers {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #FFF;
	box-shadow: 0 13px 50px rgba(35, 49, 74, 0.17), 0 7px 25px rgba(35, 49, 74, 0.1), 0 3px 5px rgba(35, 49, 74, 0.2);
	transition: all 0.4s ease-out;
	
	&__logo {
		max-height: 100%;
	}

	&--size {
		width: 100%;
		height: 200px;
		padding: 40px 30px;
	}

	@media(min-width: 576px) {
		&:hover,
		&:focus,
		&active {
			text-decoration: none;
			transform: translateY(-10px);
			box-shadow: 0 15px 55px rgba(35, 49, 74, 0.17), 0 10px 30px rgba(35, 49, 74, 0.2), 0 5px 8px rgba(35, 49, 74, 0.4);
		}
	}

	@media(min-width: 768px) {
		&--size {
			min-height: 230px;
			height: 100%;
			padding: 40px 30px;
		}
	}

	@media(min-width: 992px) {
		&--size {
			min-height: 290px;
			padding: 70px 30px;
		}
	}
}