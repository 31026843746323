.prod-item {
	
	&__img {
		position: relative;
		width: 100%;
		height: 300px;

		@media(min-width: 992px) {
			height: 100%;
		}
	} 

	&__img-main {
		position: absolute;	
		width: 110%;
		max-width: 110%;
		height: 100%;
		left: -5%;
		
		@media(min-width: 576px) {
			width: 100%;
			left: auto;
		}
		
		@media(min-width: 992px) {
			width: 220%;
			max-width: 220%;
			right: -15px;
		}
	}

	&__collection {
		position: absolute;
		bottom: -30px;
		right: 0;
		z-index: 2;
		
		@media(min-width: 576px) {
			right: 30px;
		}
		
		@media(min-width: 992px) {
			bottom: auto;
			top: 70px;
			right: -30px;
		}
		
		@media(min-width: 1200px) {
			top: 80px;
		}
	}

	&__descr {
		position: relative;
		
		@media(min-width: 992px) {
			margin-left: 65px;
			padding-bottom: 60px;
			
			&--bg {
				position: absolute;
				width: 200%;
				height: 100%;
				left: 0;
				top: 0;
				background: #FFF;
			}
		}
		
		@media(min-width: 1200px) {
			padding-bottom: 110px;
		}
	}

	&__price {
		display: inline-block;
		font-weight: 300;
		font-family: 'Lato-light';
		font-size: 20px;
		font-weight: 300;
		line-height: 46px;
		margin-top: 30px;
		
		span {
			font-family: 'Lato-medium';
			font-weight: 500;
			font-size: 28px;
		}

		@media(min-width: 576px) {
			font-size: 23px;
			line-height: 56px;
			
			span {
				font-size: 32px;
			}
		}
	}

	&__subtitle {
		font-family: 'Lato-black';
		font-size: 26px;
		font-weight: 900;
		line-height: 40px;
	}
}

.descr {
	&__list {
		font-family: 'Lato-light';
		font-weight: 300;
		list-style-type: none;		
		padding: 0;
	}

	&__item {
		font-size: 19px;
		line-height: 40px;
		font-weight: 300;
		
		span {
			font-family: 'Lato-medium';
			font-weight: 500;
		}

		img {
			width: 64px;
			height: 66px;
			margin-right: 15px;
		}

		&--flex {
			display: flex;
			align-items: center;
		}

		@media(min-width: 768px) {
			font-size: 21px;
			line-height: 48px;
			
			&--flex {
				align-items: flex-end;
			}
		}

		@media(min-width: 1200px) {
			font-size: 23px;
			line-height: 56px;
		}

	}
}

.product-colors {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-left: -14px;
	@media(min-width: 576px) {
		
	}
	
	@media(min-width: 768px) {

	}
}

.prod__input-wrapper {
	display: flex;
	flex-direction: column;
	margin: 0 14px;
	
	input {
		display: none;
		
		&:checked+label {
			box-shadow: 0 10px 20px rgba(237, 29, 127, 0.6);
		}

		&:checked~span {
			font-family: 'Lato-black';;
			font-weight: 900;
		}
	}

	label {
		cursor: pointer;
		width: 78px;
		height: 78px;
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@media(min-width: 576px) {
			width: 98px;
			height: 98px;
		}
	}

	.article {
		font-family: 'Lato-light';
		font-size: 18px;
		font-weight: 300;
		line-height: 56px;
		
		@media(min-width: 992px) {
			font-size: 23px;
		}
	}
}

.prod-item__visual {
	height: 380px;
	
	img {
		height: 100%;
		object-fit: cover;
	}

	@media(min-width: 576px) {
		width: 100%;
		height: 380px;
	}

	@media(min-width: 768px) {
		height: 430px;
	}

	@media(min-width: 992px) {
		height: 460px;
	}

	@media(min-width: 1200px) {
		width: 90%;
		height: 515px;
	}
}