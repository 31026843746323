.nav__wrapper {
	display: flex;
	justify-content: space-between;
	position: relative;
	height: 100%;
	
	&--margin {
		margin-top: 20px;
	}


	@media(min-width: 1200px) {
		&--margin {
			margin-top: 0;
		}
	}
}

.nav-paddings {
	padding: 20px 0;
}

.nav__list {
	display: none;
	list-style-type: none;
	padding-left: 0;	
	z-index: 8;
	margin: 0;
	
	&--open {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(19, 35, 41, .87);
		z-index: 8;
		
		.nav__link {
			color: #FFF;
		}
	}

	@media(min-width: 768px) {
		display: flex;
		
		&--pos-center {
			height: 100%;
			align-items: center;
			justify-content: center;
		}

		&--light {
			height: 100%;
			align-items: center;
			justify-content: flex-start;
		}
	}

	@media(min-width: 1200px) {
		&--light {
			justify-content: center;
		}
	}
}

.nav__list-item {
	&:not(:last-of-type) {
		margin-bottom: 40px;
	}

	@media(min-width: 768px) {
		&:not(:last-of-type) {
			margin-bottom: 0;
			margin-right: 40px;
		}
	}
	
}

.nav__link {
	position: relative;
	font-family: 'Raleway-medium';
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.4px;
	
	&--dark {
		&:hover,
		&:focus,
		&:active {
			color: #000000;
		}
	}

	&--light {
		&:hover,
		&:focus,
		&:active {
			color: #fff;
		}
	}
	
	&:before {
		content: "";
		position: absolute;
		left: 0;
		bottom: -8px;
		width: 0;
		height: 3px;
		background: #ed1d7f;
		transition: all 0.5s ease-out;
	}

	&:hover,
	&:focus,
	&:active {
		
		&:before {
			width: 100%;
		}
	}
}

.nav-toggler {
  position: relative;
  width: 23px;
  height: 23px;
  overflow: hidden;
    
  &__bar {
    position: absolute;
    left: 0;
    top: 4px;
    width: 100%;
    height: 2px;
    background: #fff;
    transition: all 0.3s linear;
    
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      width: 70%;
      height: 2px;
      background: #fff;
      transition: all 0.3s linear;
    }
    
    &:after {
      content: "";
      position: absolute;
      top: 12px;
      width: 100%;
      height: 2px;
      background: #fff;
      transition: all 0.3s linear;
    }

    &--dark {
		background: #000;
		
		&:before,
		&:after {
			background: #000;
		}
	  }
  }

  
  
  &.active .nav-toggler__bar {
    top: 50%;
    transform: rotate(45deg);
  }
  
  &.active .nav-toggler__bar:after {    
    top: 0;
    transform: rotate(-95deg);
  }
  
  &.active .nav-toggler__bar:before {
    left: -100%;
  }

  &.active .nav-toggler__bar--dark,
  &.active .nav-toggler__bar--dark:after,
  &.active .nav-toggler__bar--dark:before {
  	background: #FFF;
  }
}

.nav__mobile {
	
	&--open {
		position: fixed;
		z-index: 9;
		left: 30px;
		top: 20px
	}
}