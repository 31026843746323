.form__container {
	
}
.form-contacts,
.form__mobile {
	label {
		font-family: 'Lato-bold';
	}

	input,
	textarea {
		position: relative;		
		font-family: 'Lato-light';
		font-weight: 300;
		border: none;
		
		&:focus,
		&:active {
			outline: none;
		}
	}

	input::placeholder {
		color: rgba(0, 0, 0, 0.6);
		font-family: 'Lato-light';
		font-weight: 300;
	}

	.bottom-line {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 0;
		height: 2px;
		background: #ed1d7f;
		transition: all 0.4s ease-out;
	}
}

.form-contacts {
	
	label {
		font-size: 20px;
	}

	input,
	textarea {
		width: 100%;		
		font-size: 26px;

		@media(min-width: 768px) {
			font-size: 30px;
		}

		@media(min-width: 992px) {
			font-size: 32px;
		}
	}

	input::placeholder {
		font-size: 26px;
		
		@media(min-width: 768px) {
			font-size: 30px;
		}

		@media(min-width: 992px) {
			font-size: 32px;
		}
	}
}
.form-contacts__phone,
.form-contacts__email,
.form-contacts__message {
	position: relative;
	
	&>input:focus+.bottom-line,
	&>input:active+.bottom-line,
	&>textarea:focus+.bottom-line,
	&>textarea:active+.bottom-line {
		width: 100%;
	}
}

.form__mobile {

	label {
		font-size: 18px;
	}

	input,
	textarea,
	input::placeholder {
		width: 100%;
		font-size: 16px;
	}

	@media(min-width: 992px) {
		label {
			font-size: 20px;
		}

		input,
		textarea,
		input::placeholder {
			font-size: 18px;
		}
	}
}