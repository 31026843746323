.modal-body {
	position: relative;

	&--order-pad {
		padding: 30px 25px;
	}

	@media(min-width: 576px) {
		&--order-pad {
			padding: 30px 20px 30px 40px;
		}
	}
	
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 25px;
	height: 25px;
	z-index: 2;
	
	span {
		display: inline-block;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border: 2px solid rgba(19, 35, 41, 0.5);
		
		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 17%;
			left: 48%;
			width: 2px;
			height: 70%;
			background: rgba(19, 35, 41, 0.5);
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}

	@media(min-width: 992px) {
		width: 30px;
		height: 30px;
		
		&:before {
			left: 50%;
		}
	}
}