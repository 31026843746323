.card-advantage {
	&:nth-of-type(n+2) {
		margin-top: 30px;
	}

	&--btn-position {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
	}

	&--btn-position-middle {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		
		@media(min-width: 576px) {
			justify-content: center;
		}
	}

	&--btn-position-absol {
		position: absolute;
		bottom: -3%;
		right: 0;
		
		@media(min-width: 576px) {
			bottom: 0;
		}
		
		@media(min-width: 768px) {
			bottom: -20px;
		}
		
		@media(min-width: 1200px) {
			bottom: 60px;
		}
	}

	@media(min-width: 540px) {
		&:nth-of-type(n+2) {
			margin-top: 0;
		}
		&:nth-of-type(n+3) {
			margin-top: 40px;
		}
	}

	@media(min-width: 992px) {

		&:nth-of-type(n+3) {
			margin-top: 0;
		}
	}
}

.card-advantage__container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	position: relative;
	margin-left: 15px;
	margin-right: 15px;
	
	@media(min-width: 576px) {
		height: 1170px;		
		margin-left: 0;
		margin-right: 0;
	}
	
	@media(min-width: 768px) {
		height: 1110px;
	}
	
	@media(min-width: 992px) {
		height: 870px;
	}
}
.card-advantage--flex {
	width: 90%;
	padding-bottom: 40px;
	
	@media(min-width: 390px) {
		width: 70%;
	}
	
	@media(min-width: 490px) {
		width: 60%;
	}
	
	@media(min-width: 576px) {
		width: 45%;
		
		&:nth-of-type(n+4) {
			margin-left: 5%;
		}
	}

	@media(min-width: 768px) {
		padding-bottom: 60px;
	}

	@media(min-width: 992px) {
		width: 28%;
		
		&:nth-of-type(3),
		&:nth-of-type(4) {
			margin-left: 5%;
			margin-right: 5%;
		}

	}

	@media(min-width: 1200px) {
		width: 33.333%;
		
		&:nth-of-type(3),
		&:nth-of-type(4) {
			margin-left: 0;
			margin-right: 0;
		}

		&:nth-of-type(1),
		&:nth-of-type(2) {
			padding-right: 68px;
		}

		&:nth-of-type(3),
		&:nth-of-type(4) {
			padding-left: 10px;
		}

		&:nth-last-of-type(2) {
			padding-left: 80px;
		}
	}
}

.card-advantage--margins1 {

	@media(min-width: 992px) {

		&:nth-of-type(1) > div,
		&:nth-of-type(3n+1) > div {
			padding-right: 55px;
		}

		&:nth-of-type(3n) > div {
			padding-left: 55px;
		}

		&:nth-of-type(n+3) {
			margin-top: 0;
		}

		&:nth-of-type(n+4) {
			margin-top: 50px;
		}
	}
}