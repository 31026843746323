.dropdown__wrapper {
  position: relative;
  width: 100%;
  height: 65px;
  border: none;
  background-color: #ED1D7F;
  outline: none;
  cursor: pointer;
  z-index: 1;
  
  @media(min-width: 576px) {
    width: 363px;
  }
  
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style-type: none;
    background: #FFF;
    border: 1px solid rgba(88, 90, 104, 0.4);
    overflow: hidden;
    visibility: hidden;
    
    
    li {
      padding: 12px 25px;
      
      &:hover, &:active, &:focus {
        background: rgba(237, 29, 127, 0.1);
        
        a {
          text-decoration: none;
          color: #15151A;
        }
      }
    }
  }
  
  &.active .dropdown {
    visibility: visible;
    pointer-events: auto;
  }
  
  &.active .icon-v>svg {
    transform: rotate(180deg);
    transform-origin: center;
  }
}
.dropdown__active-item {
  position: absolute;
  top: 50%;
  left: 35px;
  transform: translateY(-50%);
}

.icon-v {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 35px;
  transform: translateY(-50%);
}

.tabs-container {
  position: relative;
}

.tabs__item {
  display: none;
  animation: fade 0.5s linear;
}

.tabs-box>.active {
    display: block;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}