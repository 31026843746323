.section-bg {
	position: relative;
	
	&--picture {
		background: linear-gradient(180deg, rgba(27, 28, 36, 0.8) 10%, rgba(27, 28, 36, 0.1) 40%), url("../img/bg_main-screen.jpg");
		background-size: cover;
	}

	&--wallpapers {
		background: #EFF1F3;
		
		@media(min-width: 540px) {
			background: #EFF1F3 url("../img/bg-wallpaper.png") no-repeat;
			background-position: bottom right -210px;
			background-size: 80%;
		}
		
		@media(min-width: 768px) {
			background-size: initial;
			background-position: bottom right -228px;
		}
		
		@media(min-width: 992px) {
			background-position: 63% bottom;
		}
	}
	&--wallpapers2 {
		background: #EFF1F3 url("../img/bg-wallpaper2.png") no-repeat;
		background-position: bottom 154px right -30%;
	}

	&--color {
		background: #EFF1F3;
	}

	&--instagram {
		max-width: unset;
		width: 261%;
		margin-left: -70%;
		
		@media(min-width: 1200px) {
			width: 240%;
			margin-left: -65%;
		}		
	}

	&--dark {
		padding-top: 40px;
		padding-bottom: 45px;
		background: #1A1A1F;
	}
}
.section-shadow {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 10px 60px rgba(32, 35, 53, 0.07), 0 0 5px rgba(32, 35, 53, 0.15);
	z-index: 1;
}

.section-menu {
	position: relative;
	z-index: 2;
}