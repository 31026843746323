.contacts {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	
	&__phone {
		font-family: 'Lato-bold';
		font-size: 18px;
		font-weight: 700;
		letter-spacing: 0.5px;
		line-height: 18px;
		
		@media(min-width: 992px) {
			font-size: 20px;
			line-height: 1.2;
		}
	}

	&__order {
		font-family: 'Lato-regular';
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.33px;
		line-height: 1;
		
		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			color: #ED1D7F;
		}

		@media(min-width: 992px) {
			font-size: 13px;
		}
	}

	@media(min-width: 470px) {
		// flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-end;
		align-items: flex-end;
		height: 100%;
		margin-top: 0;
	}
}


.contacts__map {
	width: 100%;
	height: 500px;
	
	img,
	iframe {
		width: 110%;
		height: 100%;
		margin-left: -5%;
	}

	@media(min-width: 576px) {
		img,
		iframe {
			width: 150%;
			margin-left: -25%;
		}
	}

	@media(min-width: 992px) {
		img,
		iframe {
			width: 144%;
			margin-left: -22%;
		}
	}

	@media(min-width: 992px) {
		img,
		iframe {
			width: 130%;
			margin-left: -15%;
		}
	}

	@media(min-width: 1200px) {
		img,
		iframe {
			width: 190%;
			margin-left: -45%;
		}
	}
}
