.icon__search {
	width: 30px;
	height: 30px;
	cursor: pointer;
	
	&--pos-absol {
		position: absolute;
		top: 50%;
		left: 10px;
		transform: translateY(-50%);
	}

	&--mobile {
		position: absolute;
	}

	@media(min-width: 1200px) {
		&--mobile {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
		}
	}
}

.icon__arrow {

	&--white {
		fill: none;
		stroke: #fff;
		stroke-width: 2px;
	}

	&--bright {
		fill: none;
		stroke: #ED1D7F;
		stroke-width: 2px;
	}
	
	&--size {
		width: 64px;
		height: 24px;
		
		@media(min-width: 470px) {
			height: 30px;
		}
		
		@media(min-width: 576px) {
			width: 70px;
			height: 34px;
		}

		@media(min-width: 768px) {
			width: 70px;
			height: 35px;
		}
	}

	&--position-rel {
		position: relative;
	}

	&--left {
		transform: rotate(180deg);
	}
}

.icon__advantage {
	svg {
		width: 88px;
		height: 83px;
		fill: none;
		stroke: #ED1D7F;
		stroke-width: 2px;
	}
	
}

.icon__insta {
	width: 48px;
	height: 48px;
	text-decoration: none;
}

.icon__bag {
	width: 24px;
	height: 24px;
	margin-left: 15px;
	
	@media(min-width: 576px) {
		width: 30px;
		height: 30px;
	}
	
	@media(min-width: 768px) {
		width: 32px;
		height: 34px;
	}
}

.icon__v {
	margin-left: 10px;
	width: 40px;
	height: 30px;
	
	fill: none;
	stroke-width: 2px;
	stroke: #15151a;
	
	@media(min-width: 992px) {
		height: 40px;
	}
}

.icon__del {
	width: 30px;
	height: 30px;
	
	@media(min-width: 768px) {
		height: 36px;
	}
}

.icon__filter {
	width: 35px;
	height: 35px;
	
	svg {
		fill: none;
		stroke: #ED1D7F;
		stroke-width: 2px;
	}

	@media(min-width: 576px) {
		width: 45px;
		height: 45px;
	}
}