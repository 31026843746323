.card {
	position: relative;
	display: block;
	
	&__wrapper {
		display: flex;
		justify-content: flex-end;
		// align-items: flex-end;
		text-decoration: none;	
		transition: all 0.4s ease-out;	

		&--dealers {
			height: 200px;
		}

		&--pad-dealers {
			padding: 30px 30px;
		}

		@media(min-width: 420px) {
			&--dealers {
				height: 220px;
			}
		}

		@media(min-width: 992px) {
			&--dealers {
				height: 250px;
			}
			&--pad-dealers {
				padding: 45px 30px;
			}
		}
	}

	&__bg {		
		
		z-index: 1;
		
		&--dealers {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
		}
	}

	&__title {
		
		&--dealers {
			font-family: 'Raleway-black';
			font-size: 34px;
			font-weight: 900;
			letter-spacing: 0.91px;
			line-height: 37px;
			z-index: 2;
		}

		@media(min-width: 576px) {
			&--dealers {
				font-size: 43px;
				line-height: 45px;
			}
		}

		@media(min-width: 992px) {
			&--dealers {
				font-size: 49px;
			}
		}

		@media(min-width: 1200px) {
			&--dealers {
				font-size: 52px;
			}
		}
	}

	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: all 0.4s ease-out;
	}

	&:before {
		box-shadow: 0 10px 30px rgba(237, 29, 127, 0.6);
	}

	&:after {
		background: rgba(237, 29, 127, 0.9);
		z-index: 1;
	}

	&:hover {
		transform: translateY(-10px);
		
		&::before,
		&::after {
			opacity: 1;
		}
	}
}