.sidebar {
	z-index: 1;
	left: -100%;
	transition: all 0.4s linear;
	
	&--open {
		left: 0;
		height: 100%;
	}
}

.section--changed-size {
	position: absolute !important;
	max-width: 90%;
	
	@media(min-width: 400px) {
		max-width: 70%;
	}
	
	@media(min-width: 500px) {
		max-width: 55%;
	}
	
	@media(min-width: 576px) {
		max-width: 50%;
	}
	
	@media(min-width: 768px) {
		max-width: 40%;
	}
	
	@media(min-width: 992px) {
		max-width: 33.333%;
		// margin-right: 2%;
	}

	@media(min-width: 1200px) {
		position: static !important;
		left: 0;
		max-width: 23%;
	}
}

.catalog-sidebar {
	position: relative;
	box-shadow: 0 13px 50px rgba(35, 49, 74, 0.17), 0 7px 25px rgba(35, 49, 74, 0.1), 0 3px 5px rgba(35, 49, 74, 0.2);
}

.sidebar__wrapper {
	display: none;
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(45, 50, 75, 0.65);
	
	&--open {
		display: block;
	}

	@media(min-width: 1200px) {
		display: none;
	}
}

.catalog-sidebar--bg  {
	background: #FFF;
}

.catalog-sidebar--pad {
	padding: 35px 25px 50px;
}

.catalog-sidebar__title {
	font-family: 'Lato-black';
	font-size: 24px;
	font-weight: 400;
	line-height: 42px;
	letter-spacing: 0.65px;
	
	&:not(:first-of-type) {
		margin-top: 25px;
	}

	@media(min-width: 576px) {
		font-size: 26px;
	}

	@media(min-width: 992px) {
		&:not(:first-of-type) {
			margin-top: 45px;
		}
	}
}

.catalog-sidebar__options {
	padding-bottom: 15px;

	input[type="checkbox"]:not(:checked),
	input[type="checkbox"]:checked,
	input[type="radio"]:not(:checked),
	input[type="radio"]:checked {
		display: none;
	}

	input[type="checkbox"]:checked  + label,
	input[type="radio"]:checked  + label {
		font-family: 'Lato-black';
		font-weight: 900;
		font-size: 16px;
	}

	input[type="checkbox"]:not(:checked) + label,
	input[type="checkbox"]:checked  + label,
	input[type="radio"]:not(:checked) + label,
	input[type="radio"]:checked  + label {
		position: relative;
		cursor: pointer;
		padding-left: 1.4em;
		line-height: 1;
		
		span {
			color: #ED1D7F;
		}
	}

	input[type="checkbox"]:not(:checked) + label:before,
	input[type="checkbox"]:checked  + label:before,
	input[type="radio"]:not(:checked) + label:before,
	input[type="radio"]:checked  + label:before {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 1em;
		height: 1em;
		border: 1px solid #000000;
	}

	input[type="checkbox"]:not(:checked) + label:after,
	input[type="checkbox"]:checked  + label:after {
		content: "✔";
		position: absolute;
		left: 0.15em;
		bottom: 0;
		font-size: 1em;
		line-height: 1;
		color: #ED1D7F;
		transition: all 0.2s linear;
	}

	input[type="radio"]:not(:checked) + label:after,
	input[type="radio"]:checked  + label:after {
		content: "";
		position: absolute;
		width: 0.6em;
		height: 0.6em;
		left: 0.19em;
		bottom: 0.19em;
		font-size: 1em;
		line-height: 1;
		background: #ED1D7F;
		transition: all 0.2s linear;
	}

	input[type="checkbox"]:not(:checked) + label:after,
	input[type="radio"]:not(:checked) + label:after {
		opacity: 0;
		transform: scale(0);
	}

	input[type="checkbox"]:checked  + label:after,
	input[type="radio"]:checked  + label:after {
		opacity: 1;
		transform: scale(1);
	}

	@media(min-width: 992px) {
		padding-bottom: 30px;
	}
}

.catalog-sidebar__item {
	font-family: 'Lato-regular';
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.4px;
	margin-top: 18px;
}

.catalog-sidebar__item1 {
	margin-top: 40px;
}

.catalog__title-wrapper {
	display: flex;
	flex-direction: column;
	
	h2 {
		order: 2;
	}

	img {
		order: 1;
		margin-bottom: 30px;
	}

	@media(min-width: 576px) {
		flex-direction: row;
		justify-content: space-between;
		
		h2, img {
			order: 0;
		}

		img {
			margin-bottom: 0;
		}
	}
}

.catalog-toggler {
	display: inline-block;
	margin-bottom: 30px;
}