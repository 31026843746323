.card-news {
	position: relative;
	transition: all 0.4s ease-out;
	
	&__img {
		width: 100%;
		min-height: 265px;
		object-fit: cover;
	}

	&__descr {
		padding: 30px 20px 20px 20px;
		background: #FFF;
		
		@media(min-width: 470px) {
			padding: 30px 40px 40px 20px;
		}
		
		@media(min-width: 1200px) {
			padding: 30px 50px 40px 20px;
		}

	}

	&__descr-title {
		.subtitle;
	}

	&__descr-text {
		.text;
		.text--size-standart;
		margin-bottom: 0;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		box-shadow: 1px 1px 1px rgba(0,0,0,0.06),
              2px 3px 3px rgba(0,0,0,0.12),
              3px 6px 6px rgba(0,0,0,0.15),
              6px 12px 12px rgba(0,0,0,0.20),
              8px 24px 24px rgba(0,0,0,0.25);
		transition: all 0.4s ease-out;
		z-index: -1;
	}

	&:hover {
		transform: translateY(-10px);
		
		&::before {
			opacity: 1;
		}
		
	}
}