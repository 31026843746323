@font-face {
  font-family: 'Lato-light';
  src: url('../fonts/Lato/Lato-Light.eot');
  src: url('../fonts/Lato/Lato-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Lato/Lato-Light.woff') format('woff'), url('../fonts/Lato/Lato-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-regular';
  src: url('../fonts/Lato/Lato-Regular.eot');
  src: url('../fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Lato/Lato-Regular.woff') format('woff'), url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-medium';
  src: url('../fonts/Lato/Lato-Medium.eot');
  src: url('../fonts/Lato/Lato-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/Lato/Lato-Medium.woff') format('woff'), url('../fonts/Lato/Lato-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-bold';
  src: url('../fonts/Lato/Lato-Bold.eot');
  src: url('../fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Lato/Lato-Bold.woff') format('woff'), url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-black';
  src: url('../fonts/Lato/Lato-Black.eot');
  src: url('../fonts/Lato/Lato-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/Lato/Lato-Black.woff') format('woff'), url('../fonts/Lato/Lato-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway-medium';
  src: url('../fonts/Raleway/Raleway-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway-extra-bold';
  src: url('../fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway-black';
  src: url('../fonts/Raleway/Raleway-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  padding: 0;
  margin: 0;
  min-height: 100%;
  position: relative;
  min-width: 320px;
  overflow-x: hidden;
}
/* fix for top margin collapsing and vertical scroll prevent for body tag, when body height = 100% */
body::before {
  content: "";
  display: block;
  width: 1px;
  height: 0px;
  background-color: transparent;
}
input,
textarea {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  resize: vertical;
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
img {
  display: block;
  max-width: 100%;
}
code,
pre {
  background-color: #f5f5f5;
  border-radius: 6px;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
  font-family: Consolas, Monaco, Lucida Console, monospace;
  font-size: 1rem;
}
pre {
  color: #666;
  line-height: 1.5;
  margin: 15px 0;
  overflow: auto;
  padding: 10px;
}
code {
  color: #666;
  font-weight: 400;
  padding: 0.3em 0.8em;
}
pre code {
  padding: 0;
}
blockquote {
  background: #f7f6f2;
  padding: 30px 80px;
  position: relative;
  width: 100%;
  margin: 2em auto;
  overflow: hidden;
  font-size: 18px;
  line-height: 1.5;
}
blockquote:before {
  content: '“';
  font-weight: 900;
  color: #c4c4c4;
  opacity: 0.2;
  font-size: 4em;
  line-height: 1em;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 0;
}
blockquote p {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.5;
  color: #2a2a2a;
  font-weight: 700;
  position: relative;
  z-index: 1;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
}
.mr-10 {
  margin-right: 10px;
}
@media (min-width: 400px) {
  .mr-45 {
    margin-right: 15px;
  }
}
@media (min-width: 768px) {
  .mr-45 {
    margin-right: 45px;
  }
}
.mr-80 {
  margin-right: 80px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-25 {
  margin-top: 25px;
}
@media (min-width: 1200px) {
  .mt-25 {
    margin-top: 0;
  }
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .mt-40 {
    margin-top: 40px;
  }
}
.mt-45 {
  margin-top: 35px;
}
@media (min-width: 1200px) {
  .mt-45 {
    margin-top: 45px;
  }
}
.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .mt-55 {
    margin-top: 55px;
  }
}
.mt-60 {
  margin-top: 30px;
}
@media (min-width: 1200px) {
  .mt-60 {
    margin-top: 60px;
  }
}
.mt-65 {
  margin-top: 40px;
}
@media (min-width: 992px) {
  .mt-65 {
    margin-top: 65px;
  }
}
.mt-70 {
  margin-top: 40px;
}
@media (min-width: 992px) {
  .mt-70 {
    margin-top: 70px;
  }
}
.mt-75 {
  margin-top: 75px;
}
.mt-80 {
  margin-top: 50px;
}
@media (min-width: 1200px) {
  .mt-80 {
    margin-top: 80px;
  }
}
.mt-82 {
  margin-top: 80px;
}
.mt-85 {
  margin-top: 50px;
}
@media (min-width: 992px) {
  .mt-85 {
    margin-top: 85px;
  }
}
.mt-90 {
  margin-top: 40px;
}
@media (min-width: 576px) {
  .mt-90 {
    margin-top: 60px;
  }
}
@media (min-width: 1200px) {
  .mt-90 {
    margin-top: 90px;
  }
}
.mt-94 {
  margin-top: 65px;
}
@media (min-width: 1200px) {
  .mt-94 {
    margin-top: 94px;
  }
}
@media (min-width: 1200px) {
  .mt-95 {
    margin-top: 95px;
  }
}
.mt-100 {
  margin-top: 60px;
}
@media (min-width: 1200px) {
  .mt-100 {
    margin-top: 100px;
  }
}
.mt-110 {
  margin-top: 70px;
}
@media (min-width: 992px) {
  .mt-110 {
    margin-top: 110px;
  }
}
.mt-130 {
  margin-top: 60px;
}
@media (min-width: 576px) {
  .mt-130 {
    margin-top: 80px;
  }
}
@media (min-width: 992px) {
  .mt-130 {
    margin-top: 130px;
  }
}
.mt-150 {
  margin-top: 60px;
}
@media (min-width: 576px) {
  .mt-150 {
    margin-top: 95px;
  }
}
@media (min-width: 992px) {
  .mt-150 {
    margin-top: 130px;
  }
}
@media (min-width: 1200px) {
  .mt-150 {
    margin-top: 150px;
  }
}
.mt-160 {
  margin-top: 90px;
}
@media (min-width: 992px) {
  .mt-160 {
    margin-top: 160px;
  }
}
.mt-170 {
  margin-top: 70px;
}
@media (min-width: 768px) {
  .mt-170 {
    margin-top: 110px;
  }
}
@media (min-width: 1200px) {
  .mt-170 {
    margin-top: 170px;
  }
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-70 {
  margin-bottom: 70px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-40 {
  padding-top: 35px;
}
@media (min-width: 992px) {
  .pt-40 {
    padding-top: 40px;
  }
}
.pt-75 {
  padding-top: 50px;
}
@media (min-width: 992px) {
  .pt-75 {
    padding-top: 75px;
  }
}
.pt-90 {
  padding-top: 60px;
}
@media (min-width: 576px) {
  .pt-90 {
    padding-top: 90px;
  }
}
.pt-110 {
  padding-top: 55px;
}
@media (min-width: 576px) {
  .pt-110 {
    padding-top: 70px;
  }
}
@media (min-width: 992px) {
  .pt-110 {
    padding-top: 110px;
  }
}
.pt-120 {
  padding-top: 80px;
}
@media (min-width: 992px) {
  .pt-120 {
    padding-top: 120px;
  }
}
.pt-130 {
  padding-top: 55px;
}
@media (min-width: 5762px) {
  .pt-130 {
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .pt-130 {
    padding-top: 130px;
  }
}
.pt-140 {
  padding-top: 90px;
}
@media (min-width: 992px) {
  .pt-140 {
    padding-top: 140px;
  }
}
.pt-155 {
  padding-top: 55px;
}
@media (min-width: 576px) {
  .pt-155 {
    padding-top: 75px;
  }
}
@media (min-width: 992px) {
  .pt-155 {
    padding-top: 110px;
  }
}
@media (min-width: 1200px) {
  .pt-155 {
    padding-top: 155px;
  }
}
.pt-160 {
  padding-top: 70px;
}
@media (min-width: 768px) {
  .pt-160 {
    padding-top: 100px;
  }
}
@media (min-width: 1200px) {
  .pt-160 {
    padding-top: 160px;
  }
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-45 {
  padding-bottom: 35px;
}
@media (min-width: 992px) {
  .pb-45 {
    padding-bottom: 45px;
  }
}
.pb-80 {
  padding-bottom: 80px;
}
@media (min-width: 576px) {
  .pb-80 {
    padding-bottom: 35px;
  }
}
@media (min-width: 768px) {
  .pb-80 {
    padding-bottom: 80px;
  }
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-110 {
  padding-bottom: 60px;
}
@media (min-width: 576px) {
  .pb-110 {
    padding-bottom: 110px;
  }
}
.pb-120 {
  padding-bottom: 50px;
}
@media (min-width: 576px) {
  .pb-120 {
    padding-bottom: 75px;
  }
}
@media (min-width: 992px) {
  .pb-120 {
    padding-bottom: 120px;
  }
}
.pb-130 {
  padding-bottom: 80px;
}
@media (min-width: 992px) {
  .pb-130 {
    padding-bottom: 130px;
  }
}
.pb-140 {
  padding-bottom: 80px;
}
@media (min-width: 576px) {
  .pb-140 {
    padding-bottom: 100px;
  }
}
@media (min-width: 992px) {
  .pb-140 {
    padding-bottom: 140px;
  }
}
.pb-160 {
  padding-bottom: 60px;
}
@media (min-width: 576px) {
  .pb-160 {
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) {
  .pb-160 {
    padding-bottom: 110px;
  }
}
@media (min-width: 992px) {
  .pb-160 {
    padding-bottom: 160px;
  }
}
.pb-170 {
  padding-bottom: 70px;
}
@media (min-width: 768px) {
  .pb-170 {
    padding-bottom: 110px;
  }
}
@media (min-width: 1200px) {
  .pb-170 {
    padding-bottom: 170px;
  }
}
.pb-180 {
  padding-bottom: 110px;
}
@media (min-width: 992px) {
  .pb-180 {
    padding-bottom: 180px;
  }
}
.pb-195 {
  padding-bottom: 65px;
}
@media (min-width: 576px) {
  .pb-195 {
    padding-bottom: 110px;
  }
}
@media (min-width: 992px) {
  .pb-195 {
    padding-bottom: 155px;
  }
}
@media (min-width: 1200px) {
  .pb-195 {
    padding-bottom: 195px;
  }
}
.pb-210 {
  padding-bottom: 60px;
}
@media (min-width: 576px) {
  .pb-210 {
    padding-bottom: 130px;
  }
}
@media (min-width: 992px) {
  .pb-210 {
    padding-bottom: 210px;
  }
}
.pb-280 {
  padding-bottom: 80px;
}
@media (min-width: 576px) {
  .pb-280 {
    padding-bottom: 120px;
  }
}
@media (min-width: 768px) {
  .pb-280 {
    padding-bottom: 180px;
  }
}
@media (min-width: 992px) {
  .pb-280 {
    padding-bottom: 280px;
  }
}
.block-margins:nth-of-type(n+2) {
  margin-top: 25px;
}
@media (min-width: 768px) {
  .block-margins:nth-of-type(n+2) {
    margin-top: 0;
  }
  .block-margins:nth-of-type(n+3) {
    margin-top: 25px;
  }
}
.block-margins1:nth-of-type(n+2) {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .block-margins1:nth-of-type(n+2) {
    margin-top: 0;
  }
  .block-margins1:nth-of-type(n+3) {
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
  .block-margins1:nth-of-type(n+3) {
    margin-top: 0;
  }
}
.block-margins2:nth-of-type(n+2) {
  margin-top: 30px;
}
@media (min-width: 576px) {
  .block-margins2:nth-of-type(n+2) {
    margin-top: 0;
  }
  .block-margins2:nth-of-type(n+3) {
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
  .block-margins2:nth-of-type(n+3) {
    margin-top: 0;
  }
  .block-margins2:nth-of-type(n+4) {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  .block-margins2:nth-of-type(n+4) {
    margin-top: 0;
  }
  .block-margins2:nth-of-type(n+5) {
    margin-top: 30px;
  }
}
.block-margins3:nth-of-type(n+2) {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .block-margins3:nth-of-type(n+2) {
    margin-top: 0;
  }
  .block-margins3:nth-of-type(n+3) {
    margin-top: 30px;
  }
}
.section-bg {
  position: relative;
}
.section-bg--picture {
  background: linear-gradient(180deg, rgba(27, 28, 36, 0.8) 10%, rgba(27, 28, 36, 0.1) 40%), url("../img/bg_main-screen.jpg");
  background-size: cover;
}
.section-bg--wallpapers {
  background: #EFF1F3;
}
@media (min-width: 540px) {
  .section-bg--wallpapers {
    background: #EFF1F3 url("../img/bg-wallpaper.png") no-repeat;
    background-position: bottom right -210px;
    background-size: 80%;
  }
}
@media (min-width: 768px) {
  .section-bg--wallpapers {
    background-size: initial;
    background-position: bottom right -228px;
  }
}
@media (min-width: 992px) {
  .section-bg--wallpapers {
    background-position: 63% bottom;
  }
}
.section-bg--wallpapers2 {
  background: #EFF1F3 url("../img/bg-wallpaper2.png") no-repeat;
  background-position: bottom 154px right -30%;
}
.section-bg--color {
  background: #EFF1F3;
}
.section-bg--instagram {
  max-width: unset;
  width: 261%;
  margin-left: -70%;
}
@media (min-width: 1200px) {
  .section-bg--instagram {
    width: 240%;
    margin-left: -65%;
  }
}
.section-bg--dark {
  padding-top: 40px;
  padding-bottom: 45px;
  background: #1A1A1F;
}
.section-shadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 10px 60px rgba(32, 35, 53, 0.07), 0 0 5px rgba(32, 35, 53, 0.15);
  z-index: 1;
}
.section-menu {
  position: relative;
  z-index: 2;
}
.nav__wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;
}
.nav__wrapper--margin {
  margin-top: 20px;
}
@media (min-width: 1200px) {
  .nav__wrapper--margin {
    margin-top: 0;
  }
}
.nav-paddings {
  padding: 20px 0;
}
.nav__list {
  display: none;
  list-style-type: none;
  padding-left: 0;
  z-index: 8;
  margin: 0;
}
.nav__list--open {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(19, 35, 41, 0.87);
  z-index: 8;
}
.nav__list--open .nav__link {
  color: #FFF;
}
@media (min-width: 768px) {
  .nav__list {
    display: flex;
  }
  .nav__list--pos-center {
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .nav__list--light {
    height: 100%;
    align-items: center;
    justify-content: flex-start;
  }
}
@media (min-width: 1200px) {
  .nav__list--light {
    justify-content: center;
  }
}
.nav__list-item:not(:last-of-type) {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .nav__list-item:not(:last-of-type) {
    margin-bottom: 0;
    margin-right: 40px;
  }
}
.nav__link {
  position: relative;
  font-family: 'Raleway-medium';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
}
.nav__link--dark:hover,
.nav__link--dark:focus,
.nav__link--dark:active {
  color: #000000;
}
.nav__link--light:hover,
.nav__link--light:focus,
.nav__link--light:active {
  color: #fff;
}
.nav__link:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 0;
  height: 3px;
  background: #ed1d7f;
  transition: all 0.5s ease-out;
}
.nav__link:hover:before,
.nav__link:focus:before,
.nav__link:active:before {
  width: 100%;
}
.nav-toggler {
  position: relative;
  width: 23px;
  height: 23px;
  overflow: hidden;
}
.nav-toggler__bar {
  position: absolute;
  left: 0;
  top: 4px;
  width: 100%;
  height: 2px;
  background: #fff;
  transition: all 0.3s linear;
}
.nav-toggler__bar:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 70%;
  height: 2px;
  background: #fff;
  transition: all 0.3s linear;
}
.nav-toggler__bar:after {
  content: "";
  position: absolute;
  top: 12px;
  width: 100%;
  height: 2px;
  background: #fff;
  transition: all 0.3s linear;
}
.nav-toggler__bar--dark {
  background: #000;
}
.nav-toggler__bar--dark:before,
.nav-toggler__bar--dark:after {
  background: #000;
}
.nav-toggler.active .nav-toggler__bar {
  top: 50%;
  transform: rotate(45deg);
}
.nav-toggler.active .nav-toggler__bar:after {
  top: 0;
  transform: rotate(-95deg);
}
.nav-toggler.active .nav-toggler__bar:before {
  left: -100%;
}
.nav-toggler.active .nav-toggler__bar--dark,
.nav-toggler.active .nav-toggler__bar--dark:after,
.nav-toggler.active .nav-toggler__bar--dark:before {
  background: #FFF;
}
.nav__mobile--open {
  position: fixed;
  z-index: 9;
  left: 30px;
  top: 20px;
}
.place-order {
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.place-order__bg,
.place-order__bg-catalog,
.place-order__bg-sell {
  width: 100%;
  min-height: 360px;
  object-fit: cover;
  background-size: cover;
}
.place-order__bg {
  background: url('../img/bg-wallpaper1.jpg') no-repeat;
}
.place-order__bg-catalog {
  background: url('../img/bg-catalog.jpg') no-repeat;
  background-size: cover;
}
.place-order__bg-sell {
  background: url('../img/bg-wallpaper3.jpg') no-repeat;
  background-size: cover;
}
.place-order__title {
  font-family: "Raleway-black";
  font-size: 57px;
  line-height: 70px;
  letter-spacing: 1.14px;
}
.place-order__title--small {
  font-size: 51px;
  line-height: 53px;
}
@media (min-width: 470px) {
  .place-order__title--small {
    font-size: 57px;
    line-height: 65px;
  }
}
@media (min-width: 576px) {
  .place-order__title {
    font-size: 57px;
    line-height: 70px;
  }
  .place-order__title--small {
    font-size: 57px;
    line-height: 70px;
  }
}
@media (min-width: 768px) {
  .place-order__title {
    font-size: 75px;
    line-height: 90px;
    letter-spacing: 1.74px;
  }
  .place-order__title--small {
    font-size: 75px;
    line-height: 90px;
  }
}
@media (min-width: 992px) {
  .place-order__title {
    font-size: 95px;
  }
  .place-order__title--small {
    font-size: 95px;
  }
}
@media (min-width: 1200px) {
  .place-order__title {
    font-size: 100px;
  }
  .place-order__title--small {
    font-size: 100px;
  }
}
.place-order__title--smaller {
  font-size: 26px;
  line-height: 38px;
  letter-spacing: 1.13px;
}
.place-order--pad-narrow {
  padding: 25px 25px;
}
@media (min-width: 480px) {
  .place-order__title--smaller {
    font-size: 39px;
    line-height: 46px;
  }
}
@media (min-width: 576px) {
  .place-order {
    padding: 50px 40px;
  }
  .place-order--pad-smaller {
    padding: 30px 40px;
  }
  .place-order--pad-narrow {
    padding: 30px 25px;
  }
  .place-order__title--smaller {
    font-size: 45px;
    line-height: 52px;
  }
}
@media (min-width: 768px) {
  .place-order {
    padding: 80px 55px;
  }
  .place-order--pad-smaller {
    padding: 45px 55px;
  }
  .place-order__bg,
  .place-order__bg-catalog,
  .place-order__bg-sell {
    min-height: 470px;
  }
  .place-order--pad-narrow {
    padding: 50px 55px;
  }
  .place-order__title--smaller {
    font-size: 58px;
    line-height: 65px;
  }
}
@media (min-width: 992px) {
  .place-order {
    padding: 100px 140px 80px 55px;
  }
  .place-order--pad-smaller {
    padding-right: 90px;
  }
  .place-order__bg,
  .place-order__bg-catalog,
  .place-order__bg-sell {
    min-height: 500px;
  }
  .place-order--pad-narrow {
    padding: 50px 55px;
  }
  .place-order__title--smaller {
    font-size: 65px;
    line-height: 65px;
  }
}
@media (min-width: 1200px) {
  .place-order {
    padding: 100px 345px 80px 55px;
  }
  .place-order--pad-smaller {
    padding-right: 200px;
  }
  .place-order--pad-narrow {
    padding: 70px 55px;
  }
}
.nav-tabs {
  margin-left: -27px;
  margin-top: 65px;
  border-bottom: none;
}
.nav-item {
  margin: 0 27px;
}
.nav-tabs .nav-item {
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .nav-tabs .nav-item {
    margin-bottom: 35px;
  }
}
.nav-tabs .nav-link {
  background-color: transparent;
  padding: 0;
  border: none;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:active {
  color: #15151a;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  position: relative;
  background-color: transparent;
  color: #15151a;
  font-family: 'Raleway-extra-bold';
  font-weight: 700;
}
.nav-tabs .nav-item.show .nav-link:before,
.nav-tabs .nav-link.active:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 3px;
  background: #ed1d7f;
}
.prod-item__img {
  position: relative;
  width: 100%;
  height: 300px;
}
@media (min-width: 992px) {
  .prod-item__img {
    height: 100%;
  }
}
.prod-item__img-main {
  position: absolute;
  width: 110%;
  max-width: 110%;
  height: 100%;
  left: -5%;
}
@media (min-width: 576px) {
  .prod-item__img-main {
    width: 100%;
    left: auto;
  }
}
@media (min-width: 992px) {
  .prod-item__img-main {
    width: 220%;
    max-width: 220%;
    right: -15px;
  }
}
.prod-item__collection {
  position: absolute;
  bottom: -30px;
  right: 0;
  z-index: 2;
}
@media (min-width: 576px) {
  .prod-item__collection {
    right: 30px;
  }
}
@media (min-width: 992px) {
  .prod-item__collection {
    bottom: auto;
    top: 70px;
    right: -30px;
  }
}
@media (min-width: 1200px) {
  .prod-item__collection {
    top: 80px;
  }
}
.prod-item__descr {
  position: relative;
}
@media (min-width: 992px) {
  .prod-item__descr {
    margin-left: 65px;
    padding-bottom: 60px;
  }
  .prod-item__descr--bg {
    position: absolute;
    width: 200%;
    height: 100%;
    left: 0;
    top: 0;
    background: #FFF;
  }
}
@media (min-width: 1200px) {
  .prod-item__descr {
    padding-bottom: 110px;
  }
}
.prod-item__price {
  display: inline-block;
  font-family: 'Lato-light';
  font-size: 20px;
  font-weight: 300;
  line-height: 46px;
  margin-top: 30px;
}
.prod-item__price span {
  font-family: 'Lato-medium';
  font-weight: 500;
  font-size: 28px;
}
@media (min-width: 576px) {
  .prod-item__price {
    font-size: 23px;
    line-height: 56px;
  }
  .prod-item__price span {
    font-size: 32px;
  }
}
.prod-item__subtitle {
  font-family: 'Lato-black';
  font-size: 26px;
  font-weight: 900;
  line-height: 40px;
}
.descr__list {
  font-family: 'Lato-light';
  font-weight: 300;
  list-style-type: none;
  padding: 0;
}
.descr__item {
  font-size: 19px;
  line-height: 40px;
  font-weight: 300;
}
.descr__item span {
  font-family: 'Lato-medium';
  font-weight: 500;
}
.descr__item img {
  width: 64px;
  height: 66px;
  margin-right: 15px;
}
.descr__item--flex {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .descr__item {
    font-size: 21px;
    line-height: 48px;
  }
  .descr__item--flex {
    align-items: flex-end;
  }
}
@media (min-width: 1200px) {
  .descr__item {
    font-size: 23px;
    line-height: 56px;
  }
}
.product-colors {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -14px;
}
.prod__input-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 14px;
}
.prod__input-wrapper input {
  display: none;
}
.prod__input-wrapper input:checked + label {
  box-shadow: 0 10px 20px rgba(237, 29, 127, 0.6);
}
.prod__input-wrapper input:checked ~ span {
  font-family: 'Lato-black';
  font-weight: 900;
}
.prod__input-wrapper label {
  cursor: pointer;
  width: 78px;
  height: 78px;
}
.prod__input-wrapper label img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 576px) {
  .prod__input-wrapper label {
    width: 98px;
    height: 98px;
  }
}
.prod__input-wrapper .article {
  font-family: 'Lato-light';
  font-size: 18px;
  font-weight: 300;
  line-height: 56px;
}
@media (min-width: 992px) {
  .prod__input-wrapper .article {
    font-size: 23px;
  }
}
.prod-item__visual {
  height: 380px;
}
.prod-item__visual img {
  height: 100%;
  object-fit: cover;
}
@media (min-width: 576px) {
  .prod-item__visual {
    width: 100%;
    height: 380px;
  }
}
@media (min-width: 768px) {
  .prod-item__visual {
    height: 430px;
  }
}
@media (min-width: 992px) {
  .prod-item__visual {
    height: 460px;
  }
}
@media (min-width: 1200px) {
  .prod-item__visual {
    width: 90%;
    height: 515px;
  }
}
.sidebar {
  z-index: 1;
  left: -100%;
  transition: all 0.4s linear;
}
.sidebar--open {
  left: 0;
  height: 100%;
}
.section--changed-size {
  position: absolute !important;
  max-width: 90%;
}
@media (min-width: 400px) {
  .section--changed-size {
    max-width: 70%;
  }
}
@media (min-width: 500px) {
  .section--changed-size {
    max-width: 55%;
  }
}
@media (min-width: 576px) {
  .section--changed-size {
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .section--changed-size {
    max-width: 40%;
  }
}
@media (min-width: 992px) {
  .section--changed-size {
    max-width: 33.333%;
  }
}
@media (min-width: 1200px) {
  .section--changed-size {
    position: static !important;
    left: 0;
    max-width: 23%;
  }
}
.catalog-sidebar {
  position: relative;
  box-shadow: 0 13px 50px rgba(35, 49, 74, 0.17), 0 7px 25px rgba(35, 49, 74, 0.1), 0 3px 5px rgba(35, 49, 74, 0.2);
}
.sidebar__wrapper {
  display: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(45, 50, 75, 0.65);
}
.sidebar__wrapper--open {
  display: block;
}
@media (min-width: 1200px) {
  .sidebar__wrapper {
    display: none;
  }
}
.catalog-sidebar--bg {
  background: #FFF;
}
.catalog-sidebar--pad {
  padding: 35px 25px 50px;
}
.catalog-sidebar__title {
  font-family: 'Lato-black';
  font-size: 24px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.65px;
}
.catalog-sidebar__title:not(:first-of-type) {
  margin-top: 25px;
}
@media (min-width: 576px) {
  .catalog-sidebar__title {
    font-size: 26px;
  }
}
@media (min-width: 992px) {
  .catalog-sidebar__title:not(:first-of-type) {
    margin-top: 45px;
  }
}
.catalog-sidebar__options {
  padding-bottom: 15px;
}
.catalog-sidebar__options input[type="checkbox"]:not(:checked),
.catalog-sidebar__options input[type="checkbox"]:checked,
.catalog-sidebar__options input[type="radio"]:not(:checked),
.catalog-sidebar__options input[type="radio"]:checked {
  display: none;
}
.catalog-sidebar__options input[type="checkbox"]:checked + label,
.catalog-sidebar__options input[type="radio"]:checked + label {
  font-family: 'Lato-black';
  font-weight: 900;
  font-size: 16px;
}
.catalog-sidebar__options input[type="checkbox"]:not(:checked) + label,
.catalog-sidebar__options input[type="checkbox"]:checked + label,
.catalog-sidebar__options input[type="radio"]:not(:checked) + label,
.catalog-sidebar__options input[type="radio"]:checked + label {
  position: relative;
  cursor: pointer;
  padding-left: 1.4em;
  line-height: 1;
}
.catalog-sidebar__options input[type="checkbox"]:not(:checked) + label span,
.catalog-sidebar__options input[type="checkbox"]:checked + label span,
.catalog-sidebar__options input[type="radio"]:not(:checked) + label span,
.catalog-sidebar__options input[type="radio"]:checked + label span {
  color: #ED1D7F;
}
.catalog-sidebar__options input[type="checkbox"]:not(:checked) + label:before,
.catalog-sidebar__options input[type="checkbox"]:checked + label:before,
.catalog-sidebar__options input[type="radio"]:not(:checked) + label:before,
.catalog-sidebar__options input[type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1em;
  height: 1em;
  border: 1px solid #000000;
}
.catalog-sidebar__options input[type="checkbox"]:not(:checked) + label:after,
.catalog-sidebar__options input[type="checkbox"]:checked + label:after {
  content: "✔";
  position: absolute;
  left: 0.15em;
  bottom: 0;
  font-size: 1em;
  line-height: 1;
  color: #ED1D7F;
  transition: all 0.2s linear;
}
.catalog-sidebar__options input[type="radio"]:not(:checked) + label:after,
.catalog-sidebar__options input[type="radio"]:checked + label:after {
  content: "";
  position: absolute;
  width: 0.6em;
  height: 0.6em;
  left: 0.19em;
  bottom: 0.19em;
  font-size: 1em;
  line-height: 1;
  background: #ED1D7F;
  transition: all 0.2s linear;
}
.catalog-sidebar__options input[type="checkbox"]:not(:checked) + label:after,
.catalog-sidebar__options input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.catalog-sidebar__options input[type="checkbox"]:checked + label:after,
.catalog-sidebar__options input[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
@media (min-width: 992px) {
  .catalog-sidebar__options {
    padding-bottom: 30px;
  }
}
.catalog-sidebar__item {
  font-family: 'Lato-regular';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
  margin-top: 18px;
}
.catalog-sidebar__item1 {
  margin-top: 40px;
}
.catalog__title-wrapper {
  display: flex;
  flex-direction: column;
}
.catalog__title-wrapper h2 {
  order: 2;
}
.catalog__title-wrapper img {
  order: 1;
  margin-bottom: 30px;
}
@media (min-width: 576px) {
  .catalog__title-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
  .catalog__title-wrapper h2,
  .catalog__title-wrapper img {
    order: 0;
  }
  .catalog__title-wrapper img {
    margin-bottom: 0;
  }
}
.catalog-toggler {
  display: inline-block;
  margin-bottom: 30px;
}
.search-area {
  width: 100%;
  position: relative;
}
.search-area__container input {
  width: 91%;
  border: 1px solid #ffffff;
  padding: 10px 20px 10px 60px;
  background: transparent;
}
.search-area__container input::placeholder {
  font-family: 'Lato-light';
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.45px;
}
.search-area__container input:focus::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.search-area__mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.search-area__mobile .search-area {
  flex-grow: 1;
  margin-bottom: 20px;
}
.search-area__mobile input {
  width: 100%;
  border: 1px solid rgba(21, 25, 27, 0.3);
  padding: 10px 20px 10px 60px;
  background: transparent;
}
.search-area__mobile input::placeholder {
  font-family: 'Lato-light';
  color: #15191b;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.45px;
}
.search-area__mobile input:focus::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.search-area__mobile--margin {
  margin-top: 20px;
}
@media (min-width: 576px) {
  .search-area__mobile {
    flex-wrap: nowrap;
  }
  .search-area__mobile .search-area {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .search-area__mobile input::placeholder {
    font-size: 18px;
  }
}
@media (min-width: 1200px) {
  .search-area__mobile--margin {
    margin-top: 0;
  }
}
.contacts {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.contacts__phone {
  font-family: 'Lato-bold';
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 18px;
}
@media (min-width: 992px) {
  .contacts__phone {
    font-size: 20px;
    line-height: 1.2;
  }
}
.contacts__order {
  font-family: 'Lato-regular';
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 1;
}
.contacts__order:hover,
.contacts__order:focus,
.contacts__order:active {
  text-decoration: none;
  color: #ED1D7F;
}
@media (min-width: 992px) {
  .contacts__order {
    font-size: 13px;
  }
}
@media (min-width: 470px) {
  .contacts {
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    margin-top: 0;
  }
}
.contacts__map {
  width: 100%;
  height: 500px;
}
.contacts__map img,
.contacts__map iframe {
  width: 110%;
  height: 100%;
  margin-left: -5%;
}
@media (min-width: 576px) {
  .contacts__map img,
  .contacts__map iframe {
    width: 150%;
    margin-left: -25%;
  }
}
@media (min-width: 992px) {
  .contacts__map img,
  .contacts__map iframe {
    width: 144%;
    margin-left: -22%;
  }
}
@media (min-width: 992px) {
  .contacts__map img,
  .contacts__map iframe {
    width: 130%;
    margin-left: -15%;
  }
}
@media (min-width: 1200px) {
  .contacts__map img,
  .contacts__map iframe {
    width: 190%;
    margin-left: -45%;
  }
}
.company-description__title {
  font-family: 'Raleway-extra-bold';
  font-size: 31px;
  font-weight: 800;
  line-height: 41px;
  letter-spacing: 2.65px;
}
@media (min-width: 470px) {
  .company-description__title {
    font-size: 50px;
    line-height: 59px;
  }
}
@media (min-width: 576px) {
  .company-description__title {
    font-size: 58px;
    line-height: 75px;
  }
}
@media (min-width: 768px) {
  .company-description__title {
    font-size: 82px;
    line-height: 100px;
  }
}
@media (min-width: 992px) {
  .company-description__title {
    font-size: 95px;
  }
}
@media (min-width: 1200px) {
  .company-description__title {
    font-size: 106px;
  }
}
.list__wrapper {
  margin-bottom: 0;
  list-style-type: none;
}
.list__item {
  position: relative;
}
.list__item:before {
  content: "—";
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
}
.list__item--company-descr {
  font-family: 'Lato-regular';
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.65px;
}
@media (min-width: 470px) {
  .list__item--company-descr {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (min-width: 576px) {
  .list__item--company-descr {
    font-size: 19px;
    line-height: 36px;
  }
}
@media (min-width: 768px) {
  .list__item--company-descr {
    font-size: 24px;
    line-height: 40px;
  }
}
@media (min-width: 992px) {
  .list__item--company-descr {
    font-size: 26px;
  }
}
.list__item--cooperation {
  font-family: 'Lato-medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 42px;
}
@media (min-width: 576px) {
  .list__item--cooperation {
    line-height: 52px;
  }
}
.list-contacts {
  list-style-type: none;
  padding-left: 0;
  margin-top: 45px;
  margin-bottom: 45px;
}
.list-contacts__item {
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
}
@media (min-width: 576px) {
  .list-contacts__item {
    font-size: 20px;
    line-height: 40px;
  }
}
@media (min-width: 992px) {
  .list-contacts__item {
    font-size: 23px;
    line-height: 56px;
  }
}
@media (min-width: 1200px) {
  .list-contacts {
    margin-bottom: 60px;
  }
}
.card-advantage:nth-of-type(n+2) {
  margin-top: 30px;
}
.card-advantage--btn-position {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.card-advantage--btn-position-middle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (min-width: 576px) {
  .card-advantage--btn-position-middle {
    justify-content: center;
  }
}
.card-advantage--btn-position-absol {
  position: absolute;
  bottom: -3%;
  right: 0;
}
@media (min-width: 576px) {
  .card-advantage--btn-position-absol {
    bottom: 0;
  }
}
@media (min-width: 768px) {
  .card-advantage--btn-position-absol {
    bottom: -20px;
  }
}
@media (min-width: 1200px) {
  .card-advantage--btn-position-absol {
    bottom: 60px;
  }
}
@media (min-width: 540px) {
  .card-advantage:nth-of-type(n+2) {
    margin-top: 0;
  }
  .card-advantage:nth-of-type(n+3) {
    margin-top: 40px;
  }
}
@media (min-width: 992px) {
  .card-advantage:nth-of-type(n+3) {
    margin-top: 0;
  }
}
.card-advantage__container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
}
@media (min-width: 576px) {
  .card-advantage__container {
    height: 1170px;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .card-advantage__container {
    height: 1110px;
  }
}
@media (min-width: 992px) {
  .card-advantage__container {
    height: 870px;
  }
}
.card-advantage--flex {
  width: 90%;
  padding-bottom: 40px;
}
@media (min-width: 390px) {
  .card-advantage--flex {
    width: 70%;
  }
}
@media (min-width: 490px) {
  .card-advantage--flex {
    width: 60%;
  }
}
@media (min-width: 576px) {
  .card-advantage--flex {
    width: 45%;
  }
  .card-advantage--flex:nth-of-type(n+4) {
    margin-left: 5%;
  }
}
@media (min-width: 768px) {
  .card-advantage--flex {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .card-advantage--flex {
    width: 28%;
  }
  .card-advantage--flex:nth-of-type(3),
  .card-advantage--flex:nth-of-type(4) {
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media (min-width: 1200px) {
  .card-advantage--flex {
    width: 33.333%;
  }
  .card-advantage--flex:nth-of-type(3),
  .card-advantage--flex:nth-of-type(4) {
    margin-left: 0;
    margin-right: 0;
  }
  .card-advantage--flex:nth-of-type(1),
  .card-advantage--flex:nth-of-type(2) {
    padding-right: 68px;
  }
  .card-advantage--flex:nth-of-type(3),
  .card-advantage--flex:nth-of-type(4) {
    padding-left: 10px;
  }
  .card-advantage--flex:nth-last-of-type(2) {
    padding-left: 80px;
  }
}
@media (min-width: 992px) {
  .card-advantage--margins1:nth-of-type(1) > div,
  .card-advantage--margins1:nth-of-type(3n+1) > div {
    padding-right: 55px;
  }
  .card-advantage--margins1:nth-of-type(3n) > div {
    padding-left: 55px;
  }
  .card-advantage--margins1:nth-of-type(n+3) {
    margin-top: 0;
  }
  .card-advantage--margins1:nth-of-type(n+4) {
    margin-top: 50px;
  }
}
.collections__container {
  width: 100%;
}
.collections__item {
  text-decoration: none;
}
.collections__item img {
  width: 100%;
}
@media (min-width: 370px) {
  .collections__item img {
    width: 90%;
  }
}
.slider__controls {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 576px) {
  .slider__controls {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
}
.slider__buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.slider__buttons div {
  cursor: pointer;
}
@media (min-width: 576px) {
  .slider__buttons {
    width: auto;
    justify-content: flex-start;
  }
}
.slider__pagination {
  display: flex;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  justify-content: center;
  margin-top: 25px;
}
.slider__pagination span:nth-of-type(2) {
  margin-left: 7px;
  margin-right: 7px;
}
@media (min-width: 576px) {
  .slider__pagination {
    width: auto;
    font-size: 20px;
    line-height: 20px;
    margin-top: 0;
    justify-content: flex-end;
  }
}
@media (min-width: 992px) {
  .slider__pagination {
    font-size: 23px;
    line-height: 32px;
  }
}
.slick-dots {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.slick-dots .slick-active button {
  width: 10px;
  height: 10px;
  background: rgba(21, 21, 26, 0.8);
}
.slick-dots li:not(:last-of-type) {
  margin-right: 15px;
}
.slick-dots button {
  font-size: 0;
  width: 8px;
  height: 8px;
  background: rgba(21, 21, 26, 0.6);
  border: none;
  padding: 0;
}
.img-presentation {
  display: grid;
  grid-template-rows: auto;
  grid-template-areas: "first" "second" "third";
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}
.img-presentation__item:first-of-type,
.img-presentation__item:nth-of-type(2),
.img-presentation__item:last-of-type {
  width: 100%;
  object-fit: cover;
}
.img-presentation__item:nth-of-type(2),
.img-presentation__item:last-of-type {
  min-height: 100%;
}
.img-presentation__item:first-of-type {
  grid-area: first;
}
.img-presentation__item:nth-of-type(2) {
  grid-area: second;
}
.img-presentation__item:last-of-type {
  grid-area: third;
}
@media (min-width: 400px) {
  .img-presentation {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "first first" "second third";
  }
}
@media (min-width: 768px) {
  .img-presentation {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "first second" "first third";
  }
}
.img-bg {
  position: absolute;
  width: 70%;
  bottom: 0;
  right: -30%;
}
@media (min-width: 768px) {
  .img-bg {
    bottom: -2%;
    right: -20%;
  }
}
@media (min-width: 992px) {
  .img-bg {
    bottom: 0;
    right: -35%;
  }
}
@media (min-width: 1200px) {
  .img-bg {
    right: -41%;
    bottom: 6%;
  }
}
.phrase-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.card-news {
  position: relative;
  transition: all 0.4s ease-out;
}
.card-news__img {
  width: 100%;
  min-height: 265px;
  object-fit: cover;
}
.card-news__descr {
  padding: 30px 20px 20px 20px;
  background: #FFF;
}
@media (min-width: 470px) {
  .card-news__descr {
    padding: 30px 40px 40px 20px;
  }
}
@media (min-width: 1200px) {
  .card-news__descr {
    padding: 30px 50px 40px 20px;
  }
}
.card-news__descr-title {
  font-family: 'Raleway-black';
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.65px;
}
@media (min-width: 1200px) {
  .card-news__descr-title {
    font-size: 24px;
    line-height: 40px;
  }
}
@media (min-width: 1200px) {
  .card-news__descr-title {
    font-size: 26px;
  }
}
.card-news__descr-text {
  font-family: 'Lato-medium';
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}
.card-news__descr-text--white {
  color: #FFF;
}
.card-news__descr-text--bright {
  color: #ED1D7F;
}
.card-news__descr-text--standart {
  color: #15151a;
}
.card-news__descr-text--black {
  color: #000000;
}
.card-news__descr-text--about-company:not(:first-of-type) {
  margin-top: 30px;
}
@media (min-width: 576px) {
  .card-news__descr-text--about-company:not(:first-of-type) {
    margin-top: 40px;
  }
}
.card-news__descr-text--emphasize {
  font-family: 'Lato-regular';
  font-size: 16px;
  line-height: 28px;
}
.card-news__descr-text--emphasize span {
  font-family: 'Lato-black';
}
@media (min-width: 400px) {
  .card-news__descr-text--emphasize {
    font-size: 20px;
    line-height: 35px;
  }
}
@media (min-width: 768px) {
  .card-news__descr-text--emphasize {
    font-size: 23px;
    line-height: 40px;
  }
}
@media (min-width: 1200px) {
  .card-news__descr-text--emphasize {
    font-size: 26px;
  }
}
.card-news__descr-text--remark {
  font-family: 'Raleway-medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.57px;
}
@media (min-width: 576px) {
  .card-news__descr-text--remark {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .card-news__descr-text--remark {
    font-size: 23px;
  }
}
.card-news__descr-text--link {
  text-decoration: none;
  font-family: 'Raleway-medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.45px;
}
@media (min-width: 768px) {
  .card-news__descr-text {
    font-size: 18px;
    line-height: 26px;
  }
}
.card-news:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.06), 2px 3px 3px rgba(0, 0, 0, 0.12), 3px 6px 6px rgba(0, 0, 0, 0.15), 6px 12px 12px rgba(0, 0, 0, 0.2), 8px 24px 24px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease-out;
  z-index: -1;
}
.card-news:hover {
  transform: translateY(-10px);
}
.card-news:hover::before {
  opacity: 1;
}
.partners-logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.partners-logo__item {
  width: 45%;
}
.partners-logo__item:nth-of-type(n+5) {
  margin-top: 30px;
}
.partners-logo__item--margin:nth-of-type(n+3) {
  margin-top: 30px;
}
@media (min-width: 400px) {
  .partners-logo__item {
    width: 30%;
  }
  .partners-logo__item--margin:nth-of-type(n+3) {
    margin-top: 0;
  }
  .partners-logo__item--margin:nth-of-type(n+4) {
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
  .partners-logo__item {
    width: auto;
  }
}
@media (min-width: 1200px) {
  .partners-logo__item--margin:nth-of-type(n+4) {
    margin-top: 0;
  }
}
.form-contacts label,
.form__mobile label {
  font-family: 'Lato-bold';
}
.form-contacts input,
.form__mobile input,
.form-contacts textarea,
.form__mobile textarea {
  position: relative;
  font-family: 'Lato-light';
  font-weight: 300;
  border: none;
}
.form-contacts input:focus,
.form__mobile input:focus,
.form-contacts textarea:focus,
.form__mobile textarea:focus,
.form-contacts input:active,
.form__mobile input:active,
.form-contacts textarea:active,
.form__mobile textarea:active {
  outline: none;
}
.form-contacts input::placeholder,
.form__mobile input::placeholder {
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Lato-light';
  font-weight: 300;
}
.form-contacts .bottom-line,
.form__mobile .bottom-line {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #ed1d7f;
  transition: all 0.4s ease-out;
}
.form-contacts label {
  font-size: 20px;
}
.form-contacts input,
.form-contacts textarea {
  width: 100%;
  font-size: 26px;
}
@media (min-width: 768px) {
  .form-contacts input,
  .form-contacts textarea {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  .form-contacts input,
  .form-contacts textarea {
    font-size: 32px;
  }
}
.form-contacts input::placeholder {
  font-size: 26px;
}
@media (min-width: 768px) {
  .form-contacts input::placeholder {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  .form-contacts input::placeholder {
    font-size: 32px;
  }
}
.form-contacts__phone,
.form-contacts__email,
.form-contacts__message {
  position: relative;
}
.form-contacts__phone > input:focus + .bottom-line,
.form-contacts__email > input:focus + .bottom-line,
.form-contacts__message > input:focus + .bottom-line,
.form-contacts__phone > input:active + .bottom-line,
.form-contacts__email > input:active + .bottom-line,
.form-contacts__message > input:active + .bottom-line,
.form-contacts__phone > textarea:focus + .bottom-line,
.form-contacts__email > textarea:focus + .bottom-line,
.form-contacts__message > textarea:focus + .bottom-line,
.form-contacts__phone > textarea:active + .bottom-line,
.form-contacts__email > textarea:active + .bottom-line,
.form-contacts__message > textarea:active + .bottom-line {
  width: 100%;
}
.form__mobile label {
  font-size: 18px;
}
.form__mobile input,
.form__mobile textarea,
.form__mobile input::placeholder {
  width: 100%;
  font-size: 16px;
}
@media (min-width: 992px) {
  .form__mobile label {
    font-size: 20px;
  }
  .form__mobile input,
  .form__mobile textarea,
  .form__mobile input::placeholder {
    font-size: 18px;
  }
}
.modal-body {
  position: relative;
}
.modal-body--order-pad {
  padding: 30px 25px;
}
@media (min-width: 576px) {
  .modal-body--order-pad {
    padding: 30px 20px 30px 40px;
  }
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  z-index: 2;
}
.close span {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid rgba(19, 35, 41, 0.5);
}
.close span:before,
.close span:after {
  content: "";
  position: absolute;
  top: 17%;
  left: 48%;
  width: 2px;
  height: 70%;
  background: rgba(19, 35, 41, 0.5);
}
.close span:before {
  transform: rotate(45deg);
}
.close span:after {
  transform: rotate(-45deg);
}
@media (min-width: 992px) {
  .close {
    width: 30px;
    height: 30px;
  }
  .close:before {
    left: 50%;
  }
}
.sertificate__item.slick-slide {
  display: flex;
  justify-content: center;
}
.sertificate__item.slick-slide img {
  max-width: 100%;
}
@media (min-width: 450px) {
  .sertificate__item.slick-slide img {
    max-width: 90%;
  }
}
.card {
  position: relative;
  display: block;
}
.card__wrapper {
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  transition: all 0.4s ease-out;
}
.card__wrapper--dealers {
  height: 200px;
}
.card__wrapper--pad-dealers {
  padding: 30px 30px;
}
@media (min-width: 420px) {
  .card__wrapper--dealers {
    height: 220px;
  }
}
@media (min-width: 992px) {
  .card__wrapper--dealers {
    height: 250px;
  }
  .card__wrapper--pad-dealers {
    padding: 45px 30px;
  }
}
.card__bg {
  z-index: 1;
}
.card__bg--dealers {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
.card__title--dealers {
  font-family: 'Raleway-black';
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.91px;
  line-height: 37px;
  z-index: 2;
}
@media (min-width: 576px) {
  .card__title--dealers {
    font-size: 43px;
    line-height: 45px;
  }
}
@media (min-width: 992px) {
  .card__title--dealers {
    font-size: 49px;
  }
}
@media (min-width: 1200px) {
  .card__title--dealers {
    font-size: 52px;
  }
}
.card:before,
.card:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.4s ease-out;
}
.card:before {
  box-shadow: 0 10px 30px rgba(237, 29, 127, 0.6);
}
.card:after {
  background: rgba(237, 29, 127, 0.9);
  z-index: 1;
}
.card:hover {
  transform: translateY(-10px);
}
.card:hover::before,
.card:hover::after {
  opacity: 1;
}
.card-comments__img-slider img {
  width: 100%;
}
@media (min-width: 470px) {
  .card-comments__img-slider img {
    width: 90%;
  }
}
.card-comments__product {
  font-style: italic;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}
@media (min-width: 576px) {
  .card-comments__product {
    font-size: 21px;
    line-height: 30px;
  }
}
@media (min-width: 768px) {
  .card-comments__product {
    font-size: 23px;
    line-height: 32px;
  }
}
.card-comments__text {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}
@media (min-width: 576px) {
  .card-comments__text {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (min-width: 768px) {
  .card-comments__text {
    font-size: 18px;
  }
}
.map {
  position: relative;
  width: 100%;
  height: 340px;
}
.map img,
.map iframe {
  position: absolute;
  width: 110%;
  height: 100%;
  top: 0;
  left: -5%;
}
@media (min-width: 576px) {
  .map {
    height: 420px;
  }
  .map img,
  .map iframe {
    width: 148%;
    height: 100%;
    left: -25%;
  }
}
@media (min-width: 768px) {
  .map {
    height: 535px;
  }
}
@media (min-width: 992px) {
  .map {
    height: 630px;
  }
  .map img,
  .map iframe {
    width: 220%;
    max-width: 220%;
    height: 100%;
    left: auto;
    right: -30px;
  }
}
@media (min-width: 1200px) {
  .map {
    height: 675px;
  }
}
.map__shop-name {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
.map__contacts {
  position: relative;
  z-index: 2;
  padding: 65px 0 60px 0;
}
.map__contacts p {
  margin-top: 25px;
  margin-bottom: 0;
}
.map__contacts p:first-of-type {
  margin-top: 45px;
}
@media (min-width: 768px) {
  .map__contacts p {
    margin-top: 35px;
  }
}
@media (min-width: 992px) {
  .map__contacts {
    padding: 65px 0 60px 65px;
  }
}
@media (min-width: 992px) {
  .map__contacts {
    padding: 120px 0 60px 65px;
  }
}
.map__phone,
.map__email,
.map__address {
  font-size: 18px;
  font-weight: 500;
}
@media (min-width: 495px) {
  .map__phone,
  .map__email,
  .map__address {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .map__phone,
  .map__email,
  .map__address {
    font-size: 23px;
  }
}
.map--bg {
  position: absolute;
  top: 0;
  left: -5%;
  width: 110%;
  height: 100%;
  background: #FFF;
  z-index: 1;
}
@media (min-width: 576px) {
  .map--bg {
    left: -50%;
    width: 200%;
  }
}
@media (min-width: 992px) {
  .map--bg {
    left: 0;
    width: 220%;
  }
}
.dropdown__wrapper {
  position: relative;
  width: 100%;
  height: 65px;
  border: none;
  background-color: #ED1D7F;
  outline: none;
  cursor: pointer;
  z-index: 1;
}
@media (min-width: 576px) {
  .dropdown__wrapper {
    width: 363px;
  }
}
.dropdown__wrapper .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style-type: none;
  background: #FFF;
  border: 1px solid rgba(88, 90, 104, 0.4);
  overflow: hidden;
  visibility: hidden;
}
.dropdown__wrapper .dropdown li {
  padding: 12px 25px;
}
.dropdown__wrapper .dropdown li:hover,
.dropdown__wrapper .dropdown li:active,
.dropdown__wrapper .dropdown li:focus {
  background: rgba(237, 29, 127, 0.1);
}
.dropdown__wrapper .dropdown li:hover a,
.dropdown__wrapper .dropdown li:active a,
.dropdown__wrapper .dropdown li:focus a {
  text-decoration: none;
  color: #15151A;
}
.dropdown__wrapper.active .dropdown {
  visibility: visible;
  pointer-events: auto;
}
.dropdown__wrapper.active .icon-v > svg {
  transform: rotate(180deg);
  transform-origin: center;
}
.dropdown__active-item {
  position: absolute;
  top: 50%;
  left: 35px;
  transform: translateY(-50%);
}
.icon-v {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 35px;
  transform: translateY(-50%);
}
.tabs-container {
  position: relative;
}
.tabs__item {
  display: none;
  animation: fade 0.5s linear;
}
.tabs-box > .active {
  display: block;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.catalog-filter label {
  margin-bottom: 0;
}
.catalog-filter input[type="checkbox"]:not(:checked),
.catalog-filter input[type="checkbox"]:checked {
  display: none;
}
.catalog-filter input[type="checkbox"]:not(:checked) + label,
.catalog-filter input[type="checkbox"]:checked + label {
  position: relative;
  cursor: pointer;
  line-height: 1;
}
.catalog-filter input[type="checkbox"]:not(:checked) + label span,
.catalog-filter input[type="checkbox"]:checked + label span {
  color: #ED1D7F;
}
.catalog-filter input[type="checkbox"]:not(:checked) + label:before,
.catalog-filter input[type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  right: -1.5em;
  bottom: 0;
  width: 1em;
  height: 1em;
  border: 1px solid #000000;
}
.catalog-filter input[type="checkbox"]:not(:checked) + label:after,
.catalog-filter input[type="checkbox"]:checked + label:after {
  content: "✔";
  position: absolute;
  right: -1.45em;
  bottom: 0;
  font-size: 1em;
  line-height: 1;
  color: #ed1d7f;
  transition: all 0.2s linear;
}
.catalog-filter input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.catalog-filter input[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.catalog-filter input[type="radio"] {
  display: none;
}
.catalog-filter input[type="radio"] + label {
  margin-left: 30px;
}
.catalog-filter input[type="radio"]:checked + label {
  position: relative;
  font-family: 'Raleway-extra-bold';
  font-weight: 700;
}
.catalog-filter input[type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 4px;
  background: #ed1d7f;
}
.catalog-filter__container,
.catalog-filter__wrap-dropdown {
  display: flex;
  flex-wrap: wrap;
}
.catalog-filter__wrap-dropdown {
  width: 100%;
  flex-direction: column;
}
@media (min-width: 576px) {
  .catalog-filter__wrap-dropdown {
    width: auto;
    flex-direction: row;
  }
}
.catalog-filter__container--align {
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 576px) {
  .catalog-filter__container--align {
    flex-direction: row;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .catalog-filter__container--align {
    width: 70%;
  }
}
@media (min-width: 1200px) {
  .catalog-filter__container--align {
    width: 100%;
  }
}
.catalog-filter__list {
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 20px 25px;
  width: 100%;
  box-shadow: 0 13px 50px rgba(35, 49, 74, 0.17), 0 7px 25px rgba(35, 49, 74, 0.1), 0 3px 5px rgba(35, 49, 74, 0.2);
  background-color: #ffffff;
  z-index: 2;
}
.catalog-filter__list input[type="checkbox"]:not(:checked) + label:before,
.catalog-filter__list input[type="checkbox"]:checked + label:before {
  left: -1.5em;
}
.catalog-filter__list input[type="checkbox"]:not(:checked) + label:after,
.catalog-filter__list input[type="checkbox"]:checked + label:after {
  right: auto;
  left: -1.45em;
}
.catalog-filter__list .catalog-filter__item {
  padding-left: 25px;
  width: 100%;
  line-height: 50px;
}
@media (min-width: 470px) {
  .catalog-filter__list {
    padding: 25px;
    width: 430px;
  }
  .catalog-filter__list .catalog-filter__item {
    padding-left: 35px;
    width: 45%;
    line-height: 70px;
  }
}
@media (min-width: 992px) {
  .catalog-filter__list {
    padding: 40px;
    width: 527px;
  }
}
.catalog-filter__item {
  font-family: 'Raleway-medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.57px;
}
@media (min-width: 992px) {
  .catalog-filter__item {
    font-size: 23px;
  }
}
.catalog-filter__list-wrap {
  display: flex;
  flex-direction: column;
}
.catalog-filter__dropdown {
  position: relative;
}
.catalog-filter__dropdown > input[type="checkbox"],
.catalog-filter__dropdown .catalog-filter__list {
  display: none;
}
.catalog-filter__dropdown > input[type="checkbox"]:checked + label {
  background-color: #ed1d7f;
  color: #FFF;
}
.catalog-filter__dropdown > input[type="checkbox"]:checked + label svg {
  stroke: #fff;
  transform: rotate(180deg);
}
.catalog-filter__dropdown > input[type="checkbox"]:checked ~ .catalog-filter__list {
  display: flex;
}
.card-product {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.card-product:hover .card-product__bg,
.card-product:focus .card-product__bg,
.card-product:active .card-product__bg,
.card-product:hover .card-product__container,
.card-product:focus .card-product__container,
.card-product:active .card-product__container {
  opacity: 1;
}
.card-product__container {
  position: relative;
  z-index: 2;
  padding: 25px 25px 35px;
  opacity: 0;
  transition: all 0.4s ease-out;
}
@media (min-width: 768px) {
  .card-product__container {
    padding: 40px 25px 35px;
  }
}
@media (min-width: 992px) {
  .card-product__container {
    padding: 40px 25px 70px;
  }
}
.card-product__bg {
  background: rgba(237, 29, 127, 0.7);
  opacity: 0;
  transition: all 0.4s ease-out;
}
.card-product__img,
.card-product__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
.card-product__article {
  display: inline-block;
  font-size: 23px;
  line-height: 32px;
  margin-top: 35px;
}
.card-product__list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.card-product__list-item {
  font-size: 18px;
  line-height: 32px;
}
.card-product__list-item span {
  font-family: 'Lato-black';
  font-weight: 900;
}
.card-product__price {
  display: inline-block;
  font-size: 18px;
  margin-top: 50px;
}
.card-product__price span {
  font-size: 32px;
}
@media (min-width: 992px) {
  .card-product__price {
    margin-top: 80px;
  }
}
.pagination {
  display: flex;
}
.pagination .arrow-back,
.pagination .arrow-forw {
  width: 25px;
  height: 100%;
  cursor: pointer;
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .pagination .arrow-back,
  .pagination .arrow-forw {
    width: 35px;
  }
}
@media (min-width: 992px) {
  .pagination .arrow-back,
  .pagination .arrow-forw {
    width: 44px;
  }
}
.pagination .arrow-back {
  transform: rotate(90deg);
}
.pagination .arrow-forw {
  transform: rotate(-90deg);
}
.pagination__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.57px;
  line-height: 1;
}
@media (min-width: 576px) {
  .pagination__list {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .pagination__list {
    font-size: 23px;
  }
}
.pagination__list-item {
  padding: 0 15px;
  cursor: pointer;
  margin-bottom: 20px;
}
.pagination__list-item.active {
  position: relative;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0.85px;
  cursor: initial;
}
.pagination__list-item.active:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -4px;
  width: 60%;
  height: 3px;
  background: #ed1d7f;
  transition: all 0.5s ease-out;
}
@media (min-width: 576px) {
  .pagination__list-item.active {
    font-size: 31px;
  }
}
@media (min-width: 992px) {
  .pagination__list-item.active {
    font-size: 34px;
  }
}
@media (min-width: 576px) {
  .pagination__list-item {
    padding: 0 20px;
  }
}
@media (min-width: 992px) {
  .pagination__list-item {
    padding: 0 25px;
  }
}
.card-suppliers {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF;
  box-shadow: 0 13px 50px rgba(35, 49, 74, 0.17), 0 7px 25px rgba(35, 49, 74, 0.1), 0 3px 5px rgba(35, 49, 74, 0.2);
  transition: all 0.4s ease-out;
}
.card-suppliers__logo {
  max-height: 100%;
}
.card-suppliers--size {
  width: 100%;
  height: 200px;
  padding: 40px 30px;
}
@media (min-width: 576px) {
  .card-suppliers:hover,
  .card-suppliers:focus,
  .card-suppliersactive {
    text-decoration: none;
    transform: translateY(-10px);
    box-shadow: 0 15px 55px rgba(35, 49, 74, 0.17), 0 10px 30px rgba(35, 49, 74, 0.2), 0 5px 8px rgba(35, 49, 74, 0.4);
  }
}
@media (min-width: 768px) {
  .card-suppliers--size {
    min-height: 230px;
    height: 100%;
    padding: 40px 30px;
  }
}
@media (min-width: 992px) {
  .card-suppliers--size {
    min-height: 290px;
    padding: 70px 30px;
  }
}
.card-collection {
  display: flex;
  align-items: flex-end;
  position: relative;
  text-decoration: none;
  background: rgba(21, 21, 26, 0.32);
}
.card-collection--size {
  width: 100%;
  height: 200px;
  padding: 25px 25px;
}
.card-collection__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-collection__title {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'Raleway-extra-bold';
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 1.09px;
  text-shadow: 6px 6px 29px rgba(35, 38, 47, 0.6), 0 5px 8px rgba(8, 10, 13, 0.7);
}
.card-collection__title span {
  font-family: 'Raleway-black';
  font-size: 30px;
  font-weight: 900;
  letter-spacing: 2.28px;
  text-transform: uppercase;
}
@media (min-width: 440px) {
  .card-collection__title span {
    font-size: 37px;
  }
}
@media (min-width: 992px) {
  .card-collection__title span {
    font-size: 42px;
  }
}
.logo {
  display: flex;
  height: 100%;
}
.logo__title {
  font-family: 'Montserrat', sans-serif;
  font-size: 38px;
  font-weight: 900;
  line-height: 0.8;
  letter-spacing: 1.05px;
}
@media (min-width: 576px) {
  .logo__title {
    font-size: 42px;
  }
}
.logo__subtitle {
  font-family: 'Lato-regular', sans-serif;
  font-size: 11px;
  line-height: 1;
}
@media (min-width: 576px) {
  .logo__subtitle {
    font-size: 13px;
    letter-spacing: 0.33px;
  }
}
.logo--pos-bottom {
  align-items: flex-end;
}
.logo--pos-center {
  align-items: center;
}
.logo--size {
  display: inline-block;
  width: 100px;
  height: 100%;
}
@media (min-width: 576px) {
  .logo--size {
    width: 130px;
  }
}
@media (min-width: 768px) {
  .logo--size {
    width: 145px;
  }
}
@media (min-width: 992px) {
  .logo--size {
    width: 165px;
  }
}
.text {
  font-family: 'Lato-medium';
}
.text--white {
  color: #FFF;
}
.text--bright {
  color: #ED1D7F;
}
.text--standart {
  color: #15151a;
}
.text--black {
  color: #000000;
}
.text--about-company:not(:first-of-type) {
  margin-top: 30px;
}
@media (min-width: 576px) {
  .text--about-company:not(:first-of-type) {
    margin-top: 40px;
  }
}
.text--emphasize {
  font-family: 'Lato-regular';
  font-size: 16px;
  line-height: 28px;
}
.text--emphasize span {
  font-family: 'Lato-black';
}
@media (min-width: 400px) {
  .text--emphasize {
    font-size: 20px;
    line-height: 35px;
  }
}
@media (min-width: 768px) {
  .text--emphasize {
    font-size: 23px;
    line-height: 40px;
  }
}
@media (min-width: 1200px) {
  .text--emphasize {
    font-size: 26px;
  }
}
.text--remark {
  font-family: 'Raleway-medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.57px;
}
@media (min-width: 576px) {
  .text--remark {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .text--remark {
    font-size: 23px;
  }
}
.text--link {
  text-decoration: none;
  font-family: 'Raleway-medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.45px;
}
.text--size-standart {
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .text--size-standart {
    font-size: 18px;
    line-height: 26px;
  }
}
.text--lh32 {
  line-height: 32px;
}
.text--contacts {
  font-size: 18px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0.57px;
  margin: 0;
}
@media (min-width: 768px) {
  .text--contacts {
    font-size: 20px;
    line-height: 48px;
  }
}
@media (min-width: 768px) {
  .text--contacts {
    font-size: 23px;
    line-height: 56px;
  }
}
.link:hover,
.link:focus,
.link:active {
  color: #ED1D7F;
}
.icon__search {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.icon__search--pos-absol {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.icon__search--mobile {
  position: absolute;
}
@media (min-width: 1200px) {
  .icon__search--mobile {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
.icon__arrow--white {
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
}
.icon__arrow--bright {
  fill: none;
  stroke: #ED1D7F;
  stroke-width: 2px;
}
.icon__arrow--size {
  width: 64px;
  height: 24px;
}
@media (min-width: 470px) {
  .icon__arrow--size {
    height: 30px;
  }
}
@media (min-width: 576px) {
  .icon__arrow--size {
    width: 70px;
    height: 34px;
  }
}
@media (min-width: 768px) {
  .icon__arrow--size {
    width: 70px;
    height: 35px;
  }
}
.icon__arrow--position-rel {
  position: relative;
}
.icon__arrow--left {
  transform: rotate(180deg);
}
.icon__advantage svg {
  width: 88px;
  height: 83px;
  fill: none;
  stroke: #ED1D7F;
  stroke-width: 2px;
}
.icon__insta {
  width: 48px;
  height: 48px;
  text-decoration: none;
}
.icon__bag {
  width: 24px;
  height: 24px;
  margin-left: 15px;
}
@media (min-width: 576px) {
  .icon__bag {
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .icon__bag {
    width: 32px;
    height: 34px;
  }
}
.icon__v {
  margin-left: 10px;
  width: 40px;
  height: 30px;
  fill: none;
  stroke-width: 2px;
  stroke: #15151a;
}
@media (min-width: 992px) {
  .icon__v {
    height: 40px;
  }
}
.icon__del {
  width: 30px;
  height: 30px;
}
@media (min-width: 768px) {
  .icon__del {
    height: 36px;
  }
}
.icon__filter {
  width: 35px;
  height: 35px;
}
.icon__filter svg {
  fill: none;
  stroke: #ED1D7F;
  stroke-width: 2px;
}
@media (min-width: 576px) {
  .icon__filter {
    width: 45px;
    height: 45px;
  }
}
.btn {
  font-family: 'Raleway-medium';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.57px;
  display: flex;
}
.btn:hover,
.btn:focus,
.btn:active {
  outline: none;
}
.btn--bright {
  box-shadow: 0 10px 20px rgba(237, 29, 127, 0.6);
  background-color: #ed1d7f;
}
.btn--bright:hover,
.btn--bright:focus,
.btn--bright:active {
  box-shadow: 0 10px 20px rgba(237, 29, 127, 0.8);
  color: #FFF;
}
.btn--transp,
.btn-slider {
  border: none;
  background: transparent;
  padding: 0;
}
.btn--transp:hover,
.btn-slider:hover,
.btn--transp:focus,
.btn-slider:focus,
.btn--transp:active,
.btn-slider:active {
  border: none;
  outline: none;
  color: #ED1D7F;
}
.btn--size {
  width: fit-content;
}
.btn--size-filter {
  display: inline-block;
  width: 100%;
}
.btn--full-size {
  width: 100%;
  display: flex;
  justify-content: center;
}
.btn--pad-stand {
  padding: 1.1em 2em;
}
.btn--pad-modal {
  padding: 0.5em 1em;
}
.btn--pad-modal-order {
  padding: 0.5em 1em;
}
.btn--pad-wide {
  padding: 1.3em 4em;
}
.btn--pad-filter {
  padding: 0.8em 0.2em;
}
@media (min-width: 470px) {
  .btn {
    font-size: 18px;
  }
}
@media (min-width: 576px) {
  .btn {
    font-size: 20px;
  }
  .btn--pad-stand {
    padding: 1.3em 2.61em;
  }
  .btn--pad-modal {
    padding: 0 0.5em;
  }
}
@media (min-width: 768px) {
  .btn {
    font-size: 23px;
  }
}
@media (min-width: 992px) {
  .btn--pad-modal-order {
    padding: 0.7em 1.2em;
  }
}
.btn__filter {
  font-size: 18px;
  width: fit-content;
  margin: 0;
}
.btn__filter--pad {
  padding: 1em 1.4em;
}
.btn__filter:hover,
.btn__filter:focus,
.btn__filter:active {
  background-color: #ed1d7f;
  color: #FFF;
}
.btn__filter:hover svg,
.btn__filter:focus svg,
.btn__filter:active svg {
  stroke: #fff;
}
@media (min-width: 768px) {
  .btn__filter {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .btn__filter {
    font-size: 23px;
  }
}
.btn--reset {
  font-family: 'Raleway-extra-bold';
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 0.57px;
  margin-top: 30px;
}
.btn--reset:hover,
.btn--reset:focus,
.btn--reset:active {
  color: #15151a;
}
@media (min-width: 768px) {
  .btn--reset {
    font-size: 23px;
  }
}
@media (min-width: 1200px) {
  .btn--reset {
    margin-top: 0;
  }
}
.title,
.subtitle {
  font-family: 'Raleway-black';
  font-weight: 900;
}
.title {
  font-size: 29px;
  line-height: 36px;
  letter-spacing: 1.73px;
}
.title span {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1.73px;
}
@media (min-width: 470px) {
  .title {
    font-size: 36px;
    line-height: 45px;
  }
}
@media (min-width: 576px) {
  .title {
    font-size: 45px;
    line-height: 60px;
  }
}
@media (min-width: 768px) {
  .title {
    font-size: 60px;
    line-height: 70px;
  }
}
@media (min-width: 992px) {
  .title {
    font-size: 69px;
  }
}
.title--smaller {
  font-size: 27px;
}
@media (min-width: 495px) {
  .title--smaller {
    font-size: 46px;
  }
}
@media (min-width: 576px) {
  .title--smaller {
    font-size: 52px;
  }
}
@media (min-width: 768px) {
  .title--smaller {
    font-size: 62px;
  }
}
@media (min-width: 1200px) {
  .title--smaller {
    font-size: 69px;
  }
}
.title--small-size {
  font-size: 24px;
  line-height: 34px;
}
@media (min-width: 470px) {
  .title--small-size {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (min-width: 576px) {
  .title--small-size {
    font-size: 41px;
    line-height: 52px;
  }
}
@media (min-width: 768px) {
  .title--small-size {
    font-size: 56px;
    line-height: 70px;
  }
}
.subtitle {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.65px;
}
@media (min-width: 1200px) {
  .subtitle {
    font-size: 24px;
    line-height: 40px;
  }
}
@media (min-width: 1200px) {
  .subtitle {
    font-size: 26px;
  }
}
.subtitle--bigger {
  font-size: 32px;
  letter-spacing: 1.05px;
  line-height: 1.3;
}
@media (min-width: 576px) {
  .subtitle--bigger {
    font-size: 38px;
  }
}
@media (min-width: 768px) {
  .subtitle--bigger {
    line-height: 1;
    font-size: 42px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1170px;
  }
}
hr {
  height: 1px;
  background-color: #1b1c24;
  opacity: 0.2;
}
.position-rel {
  position: relative;
}
@media (min-width: 384px) {
  .d-xs-block {
    display: inline-block !important;
  }
}
@media (min-width: 380px) {
  .d-xs-none {
    display: none;
  }
}
@media (min-width: 400px) {
  .d-400-block {
    display: inline-block !important;
  }
}
@media (min-width: 460px) {
  .width-fluid {
    width: 80%;
  }
}
@media (min-width: 540px) {
  .width-fluid {
    width: 70%;
  }
}
@media (min-width: 576px) {
  .width-fluid {
    width: auto;
  }
}
@media (min-width: 460px) {
  .width-fluid1 {
    width: 70%;
  }
}
@media (min-width: 540px) {
  .width-fluid1 {
    width: 50%;
  }
}
@media (min-width: 576px) {
  .width-fluid1 {
    width: auto;
  }
}
.width-fluid--header {
  width: 100%;
}
@media (min-width: 470px) {
  .width-fluid--header {
    width: 50%;
  }
}
@media (min-width: 576px) {
  .width-fluid--header {
    width: auto;
  }
}
