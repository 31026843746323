.company-description {
	&__title {
		font-family: 'Raleway-extra-bold';
		font-size: 31px;
		font-weight: 800;
		line-height: 41px;
		letter-spacing: 2.65px;
	}

	@media(min-width: 470px) {
		&__title {
			font-size: 50px;
			line-height: 59px;
		}
	}

	@media(min-width: 576px) {
		&__title {
			font-size: 58px;
			line-height: 75px;
		}
	}

	@media(min-width: 768px) {
		&__title {
			font-size: 82px;
			line-height: 100px;
		}
	}

	@media(min-width: 992px) {
		&__title {
			font-size: 95px;
		}
	}

	@media(min-width: 1200px) {
		&__title {
			font-size: 106px;
		}
	}
}