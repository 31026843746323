// font-family: 'Lato-light';
// font-family: 'Lato-regular';
// font-family: 'Lato-medium';
// font-family: 'Lato-bold';
// font-family: 'Lato-black';
// font-family: 'Raleway-medium';
// font-family: 'Raleway-extra-bold';
// font-family: 'Raleway-black';
// font-family: 'Montserrat', sans-serif;

// Fonts
@import "fonts/_fonts.less";

// Base
@import "base/_base.less";
@import "base/_margins.less";

// Sections
@import "sections/_section-bg.less";
@import "sections/_nav.less";
@import "sections/_place-order.less";
@import "sections/_tabs.less";
@import "sections/_product.less";
@import "sections/_catalog-collections.less";

//  Blocks
@import "blocks/_search-area.less";
@import "blocks/_contacts.less";
@import "blocks/_company-descr.less";
@import "blocks/_list.less";
@import "blocks/_card-advantage.less";
@import "blocks/_collections.less";
@import "blocks/_slider.less";
@import "blocks/_img.less";
@import "blocks/_phrase-wrapper.less";
@import "blocks/_card-news.less";
@import "blocks/_partners-logo.less";
@import "blocks/_form.less";
@import "blocks/_modal.less";
@import "blocks/_sertificate.less";
@import "blocks/_card.less";
@import "blocks/_card-comments.less";
@import "blocks/_map.less";
@import "blocks/_dropdown.less";
@import "blocks/_catalog-filter.less";
@import "blocks/_card-product.less";
@import "blocks/_pagination.less";
@import "blocks/_card-suppliers.less";
@import "blocks/_card-collecton.less";

// Elements
@import "elements/_logo.less";
@import "elements/_text.less";
@import "elements/_icon.less";
@import "elements/_button.less";
@import "elements/_title.less";


@media (min-width: 1200px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
	    max-width: 1170px;
	}
}

hr {
	height: 1px;
	background-color: #1b1c24;
	opacity: 0.2;
}
.position-rel {
	position: relative;
}
.d-xs-block {

	@media(min-width: 384px) {
		display: inline-block !important;
	}
}

.d-xs-none {
	@media(min-width: 380px) {
		display: none;
	}
}
.d-400-block {

	@media(min-width: 400px) {
		display: inline-block !important;
	}
}
.width-fluid {

	@media(min-width: 460px) {
		width: 80%;
	}
	@media(min-width: 540px) {
		width: 70%;
	}
	@media(min-width: 576px) {
		width: auto;
	}
}

.width-fluid1 {

	@media(min-width: 460px) {
		width: 70%;
	}
	@media(min-width: 540px) {
		width: 50%;
	}
	@media(min-width: 576px) {
		width: auto;
	}
}

.width-fluid--header {
	width: 100%;
	
	@media(min-width: 470px) {
		width: 50%;
	}
	@media(min-width: 576px) {
		width: auto;
	}
}