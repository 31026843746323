.partners-logo {
	display: flex;
	flex-wrap: 	wrap;
	justify-content: space-between;
	align-items: center;
	
	&__item {
		width: 45%;
		&:nth-of-type(n+5) {
			margin-top: 30px;	
		}
	}

	&__item--margin {

		&:nth-of-type(n+3) {
			margin-top: 30px;	
		}
	}

	@media(min-width: 400px) {

		&__item {
			width: 30%;
		}

		&__item--margin {

			&:nth-of-type(n+3) {
				margin-top: 0;	
			}

			&:nth-of-type(n+4) {
				margin-top: 30px;	
			}
		}
	}

	@media(min-width: 992px) {

		&__item {
			width: auto;
		}
	}

	@media(min-width: 1200px) {
		&__item--margin {

			&:nth-of-type(n+4) {
				margin-top: 0;	
			}
		}
	}
}