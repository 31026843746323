* {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

html, body {
	font-family: 'Open Sans', sans-serif;
    font-size: 16px;
	padding: 0;
	margin: 0;
	min-height: 100%;
    position: relative;
    min-width: 320px;
    overflow-x: hidden;
}

/* fix for top margin collapsing and vertical scroll prevent for body tag, when body height = 100% */
body::before {
	content: "";
	display: block;
	width: 1px;
	height: 0px;
	background-color: transparent;
}

input, textarea {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	line-height: 1.5;
	resize: vertical;
    outline: none;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

img {
	display: block;
    max-width: 100%;
}

code, pre {
    background-color: #f5f5f5;
    border-radius: 6px;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    font-family: monospace;
    font-family: Consolas, Monaco, Lucida Console, monospace;
    font-size: 1rem;
}

pre {
    color: #666;
    line-height: 1.5;
    margin: 15px 0;
    overflow: auto;
    padding: 10px;
}

code {
    color: #666;
    font-weight: 400;
    padding: .3em .8em;
}

pre code {
    padding: 0;
}

blockquote {
    background: #f7f6f2;
    padding: 30px 80px;
    position: relative;
    width: 100%;
    margin: 2em auto;
    overflow: hidden;
    font-size: 18px;
    line-height: 1.5;
}

blockquote:before {
    content: '“';
    font-weight: 900;
    color: #c4c4c4;
    opacity: 0.2;
    font-size: 4em;
    line-height: 1em;
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 0;
}

blockquote p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.5;
    color: #2a2a2a;
    font-weight: 700;
    position: relative;
    z-index: 1;
}

a {
    &:hover, &:focus, &:active {
        text-decoration: none;
    }
}