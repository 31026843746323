.list {	

	&__wrapper {
		margin-bottom: 0;		
		list-style-type: none;
	}

	&__item {
		position: relative;
		
		&:before {
			content: "—";
			position: absolute;
			left: -40px;
			top: 50%;
			transform: translateY(-50%);
		}

		&--company-descr {
			font-family: 'Lato-regular';
			font-size: 16px;
			line-height: 26px;
			letter-spacing: 0.65px;
			
			@media(min-width: 470px) {
				font-size: 18px;
				line-height: 32px;
			}
			
			@media(min-width: 576px) {
				font-size: 19px;
				line-height: 36px;
			}
			
			@media(min-width: 768px) {
				font-size: 24px;
				line-height: 40px;
			}
			@media(min-width: 992px) {
				font-size: 26px;
			}
		}

		&--cooperation {
			font-family: 'Lato-medium';
			font-size: 18px;
			font-weight: 500;
			line-height: 42px;
			
			@media(min-width: 576px) {
				line-height: 52px;
			}
		}
	}

}

.list-contacts {
	list-style-type: none;
	padding-left: 0;
	margin-top: 45px;
	margin-bottom: 45px;
	
	&__item {
		font-size: 18px;
		font-weight: 500;
		line-height: 36px;
	}

	@media(min-width: 576px) {
		&__item {
			font-size: 20px;
			line-height: 40px;
		}
	}

	@media(min-width: 992px) {
		&__item {
			font-size: 23px;
			line-height: 56px;
		}
	}

	@media(min-width: 1200px) {
		margin-bottom: 60px;
	}
}