.sertificate {

	&__container {

	}

	&__item.slick-slide {
		display: flex;
		justify-content: center;

		img {
			max-width: 100%;
		}
	}

	@media(min-width: 450px) {
		&__item.slick-slide {

			img {
				max-width: 90%;
			}
		}
	}
}