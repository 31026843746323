.search-area {
	width: 100%;
	position: relative;
	
	&__container {

		input {
			width: 91%;
			border: 1px solid #ffffff;
			// background-color: #15191b;
			padding: 10px 20px 10px 60px;
			background: transparent;
			
			&::placeholder {
				font-family: 'Lato-light';
				color: rgba(255, 255, 255, 0.9);
				font-size: 18px;
				font-weight: 300;
				letter-spacing: 0.45px;
			}

			&:focus::placeholder {
				color: rgba(255, 255, 255, 0.7);
			}
		}
	}

	&__mobile {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		
		.search-area {
			flex-grow: 1;
			margin-bottom: 20px;
		}
		
		input {
			width: 100%;
			border: 1px solid rgba(21, 25, 27, 0.3);
			// background-color: #15191b;
			padding: 10px 20px 10px 60px;
			background: transparent;
			
			&::placeholder {
				font-family: 'Lato-light';
				color: #15191b;
				font-size: 16px;
				font-weight: 300;
				letter-spacing: 0.45px;
			}

			&:focus::placeholder {
				color: rgba(255, 255, 255, 0.7);
			}
		}

		&--margin {
			margin-top: 20px;
		}

		@media(min-width: 576px) {
			flex-wrap: nowrap;
			
			.search-area {
				margin-right: 15px;
				margin-bottom: 0;
			}

			input::placeholder {
				font-size: 18px;
			}
		}

		@media(min-width: 1200px) {
			&--margin {
				margin-top: 0;
			}
		}
	}
}