.text {
font-family: 'Lato-medium';
	
	&--white {
		color: #FFF;
	}

	&--bright {
		color: #ED1D7F;
	}

	&--standart {
		color: #15151a;
	}

	&--black {
		color: #000000;
	}

	&--about-company {
		
		&:not(:first-of-type) {
			margin-top: 30px;
		}

		@media(min-width: 576px) {
			&:not(:first-of-type) {
				margin-top: 40px;
			}
		}
	}

	&--emphasize {
		font-family: 'Lato-regular';
		font-size: 16px;
		line-height: 28px;

		span {
			font-family: 'Lato-black';
		}

		@media(min-width: 400px) {
			font-size: 20px;
			line-height: 35px;
		}

		@media(min-width: 768px) {
			font-size: 23px;
			line-height: 40px;
		}

		@media(min-width: 1200px) {
			font-size: 26px;
		}
	}

	&--remark {
		font-family: 'Raleway-medium';
		font-size: 18px;
		font-weight: 500;
		letter-spacing: 0.57px;
		
		@media(min-width: 576px) {
			font-size: 20px;
		}

		@media(min-width: 768px) {
			font-size: 23px;
		}
	}

	&--link {
		text-decoration: none;
		font-family: 'Raleway-medium';
		font-size: 18px;
		font-weight: 500;
		letter-spacing: 0.45px;
	}
}

.text--size-standart {
	font-size: 16px;
	line-height: 24px;
	
	@media(min-width: 768px) {
		font-size: 18px;
		line-height: 26px;
	}
}

.text--lh32 {
	line-height: 32px;
}

.text--contacts {
	font-size: 18px;
	font-weight: 500;
	line-height: 45px;
	letter-spacing: 0.57px;
	margin: 0;
	
	@media(min-width: 768px) {
		font-size: 20px;
		line-height: 48px;
	}
	
	@media(min-width: 768px) {
		font-size: 23px;
		line-height: 56px;
	}
}

.link {
	&:hover,
	&:focus,
	&:active {
		color: #ED1D7F;
	}
}