.pagination {
	display: flex;
	// flex-wrap: wrap;
	
	.arrow-back,
	.arrow-forw {
		width: 25px;
		height: 100%;
		cursor: pointer;
		margin-bottom: 20px;
		
		@media(min-width: 576px) {
			width: 35px;
		}
		
		@media(min-width: 992px) {
			width: 44px;
		}
	}

	.arrow-back {
		transform: rotate(90deg);
	}

	.arrow-forw {
		transform: rotate(-90deg);
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
		font-family: 'Montserrat', sans-serif;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.57px;
		line-height: 1;
		
		@media(min-width: 576px) {
			font-size: 20px;
		}
		
		@media(min-width: 992px) {
			font-size: 23px;
		}
	}

	&__list-item {
		padding: 0 15px;
		cursor: pointer;
		margin-bottom: 20px;
		
		&.active {
			position: relative;
			font-size: 26px;
			font-weight: 700;
			letter-spacing: 0.85px;
			cursor: initial;
			
			&:before {
				content: "";
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: -4px;
				width: 60%;
				height: 3px;
				background: #ed1d7f;
				transition: all 0.5s ease-out;
			}

			@media(min-width: 576px) {
				font-size: 31px;
			}

			@media(min-width: 992px) {
				font-size: 34px;
			}
		}

		@media(min-width: 576px) {
			padding: 0 20px;
		}

		@media(min-width: 992px) {
			padding: 0 25px;
		}
	}
}