.btn {
	font-family: 'Raleway-medium';
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.57px;
	display: flex;
	
	&:hover,
	&:focus,
	&:active {
		outline: none;
	}
	
	&--bright {
		box-shadow: 0 10px 20px rgba(237, 29, 127, 0.6);
		background-color: #ed1d7f;
		
		&:hover,
		&:focus,
		&:active {
			box-shadow: 0 10px 20px rgba(237, 29, 127, 0.8);
			color: #FFF;
		}
	}

	&--transp,
	&-slider {
		border: none;
		background: transparent;
		padding: 0;
		
		&:hover,
		&:focus,
		&:active {
			border: none;
			outline: none;
			color: #ED1D7F;
		}
	}

	&--size {
		width: fit-content;
	}

	&--size-filter {
		display: inline-block;
		width: 100%;
	}

	&--full-size {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	&--pad-stand {
		padding: 1.1em 2em;
	}

	&--pad-modal {
		padding: 0.5em 1em;
	}

	&--pad-modal-order {
		padding: 0.5em 1em;
	}

	&--pad-wide {
		padding: 1.3em 4em;
	}

	&--pad-filter {
		padding: 0.8em 0.2em;
	}

	@media(min-width: 470px) {
		font-size: 18px;
		
		&--pad-stand {
		}
	}

	@media(min-width: 576px) {
		font-size: 20px;
		
		&--pad-stand {
			padding: 1.3em 2.61em;
		}

		&--pad-modal {
			padding: 0 0.5em;
		}
	}

	@media(min-width: 768px) {
		font-size: 23px;
	}

	@media(min-width: 992px) {
		&--pad-modal-order {
			padding: 0.7em 1.2em;
		}
	}
}

.btn__filter {
	font-size: 18px;
	width: fit-content;
	margin: 0;

	&--pad {
		padding: 1em 1.4em;
	}

	&:hover,
	&:focus,
	&:active {
		background-color: #ed1d7f;
		color: #FFF;
		svg {
			stroke: #fff;
		}
		
	}

	@media(min-width: 768px) {
		font-size: 20px;
	}

	@media(min-width: 992px) {
		font-size: 23px;
	}
}

.btn--reset {
	font-family: 'Raleway-extra-bold';
	font-size: 19px;
	font-weight: 700;
	letter-spacing: 0.57px;
	margin-top: 30px;
	
	&:hover,
	&:focus,
	&:active {
		color: #15151a;
	}

	@media(min-width: 768px) {
		font-size: 23px;
	}

	@media(min-width: 1200px) {
		margin-top: 0;
	}
}