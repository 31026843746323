.nav-tabs {
	margin-left: -27px;
	margin-top: 65px;
	border-bottom: none;
}

.nav-item {
	margin: 0 27px;	
}

.nav-tabs .nav-item {
	margin-bottom: 20px;
	
	@media(min-width: 576px) {
		margin-bottom: 35px;
	}
}

.nav-tabs .nav-link {
	background-color: transparent;
	padding: 0;
	border: none;
	
	&:hover,
	&:focus,
	&:active {
		color: #15151a;
	}
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	position: relative;
	background-color: transparent;
	color: #15151a;
	font-family: 'Raleway-extra-bold';
	font-weight: 700;
	
	&:before {
		content: "";
		position: absolute;
		left: 0;
		bottom: -4px;
		width: 100%;
		height: 3px;
		background: #ed1d7f;
	}
}