.title,
.subtitle {
	font-family: 'Raleway-black';
	font-weight: 900;
}

.title {	
	font-size: 29px;	
	line-height: 36px;
	letter-spacing: 1.73px;
	
	span {
		font-family: 'Montserrat', sans-serif;
		font-weight: 700;
		letter-spacing: 1.73px;
	}
	@media(min-width: 470px) {
		font-size: 36px;	
		line-height: 45px;
	}

	@media(min-width: 576px) {
		font-size: 45px;	
		line-height: 60px;
	}

	@media(min-width: 768px) {
		font-size: 60px;	
		line-height: 70px;
	}

	@media(min-width: 992px) {
		font-size: 69px;
	}
}

.title--smaller {
	font-size: 27px;
	
	@media(min-width: 495px) {
		font-size: 46px;
	}
	
	@media(min-width: 576px) {
		font-size: 52px;
	}
	@media(min-width: 768px) {
		font-size: 62px;
	}

	@media(min-width: 1200px) {
		font-size: 69px;
	}
}

.title--small-size {
	font-size: 24px;	
	line-height: 34px;
	
	@media(min-width: 470px) {
		font-size: 36px;	
		line-height: 46px;
	}
	
	@media(min-width: 576px) {
		font-size: 41px;	
		line-height: 52px;
	}

	@media(min-width: 768px) {
		font-size: 56px;	
		line-height: 70px;
	}
}

.subtitle {
	font-size: 20px;
	line-height: 26px;
	letter-spacing: 0.65px;
	
	@media(min-width: 1200px) {
		font-size: 24px;
		line-height: 40px;
	}
	@media(min-width: 1200px) {
		font-size: 26px;
	}
}

.subtitle--bigger {
	font-size: 32px;
	letter-spacing: 1.05px;
	line-height: 1.3;
	
	@media(min-width: 576px) {
		font-size: 38px;
	}
	
	@media(min-width: 768px) {
		line-height: 1;
		font-size: 42px;
	}
}

.title--filter {
	
}