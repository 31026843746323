.place-order {
	padding: 30px 25px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	&__bg,
	&__bg-catalog,
	&__bg-sell {
		width: 100%;
		min-height: 360px;
		object-fit: cover;
		background-size: cover;
	}
	&__bg {
		background: url('../img/bg-wallpaper1.jpg') no-repeat;
	}

	&__bg-catalog {
		background: url('../img/bg-catalog.jpg') no-repeat;
		background-size: cover;
	}

	&__bg-sell {
		background: url('../img/bg-wallpaper3.jpg') no-repeat;
		background-size: cover;
	}

	&__title {
		font-family: "Raleway-black";
		font-size: 57px;
		line-height: 70px;
		letter-spacing: 1.14px;
		
		&--small {
			font-size: 51px;
			line-height: 53px;
		}

		@media(min-width: 470px) {
			&--small {
				font-size: 57px;
				line-height: 65px;
			}
		}
		
		@media(min-width: 576px) {
			font-size: 57px;
			line-height: 70px;
			
			&--small {
				font-size: 57px;
				line-height: 70px;
			}
		}
		
		@media(min-width: 768px) {
			font-size: 75px;
			line-height: 90px;
			letter-spacing: 1.74px;
			
			&--small {
				font-size: 75px;
				line-height: 90px;
			}
		}
		
		@media(min-width: 992px) {
			font-size: 95px;

			&--small {
				font-size: 95px;
			}
		}
		
		@media(min-width: 1200px) {
			font-size: 100px;
			
			&--small {
				font-size: 100px;
			}
		}
	}	

	&__title--smaller {
		font-size: 26px;
		line-height: 38px;
		letter-spacing: 1.13px;
	}

	&--pad-smaller {
		// padding-right: 90px;
	}

	&--pad-narrow {
		padding: 25px 25px;
	}

	@media(min-width: 480px) {

		&__title--smaller {
			font-size: 39px;
			line-height: 46px;
		}
	}

	@media(min-width: 576px) {
		padding: 50px 40px;
		
		&--pad-smaller {
			padding: 30px 40px;
		}

		&--pad-narrow {
			padding: 30px 25px;
		}

		&__title--smaller {
			font-size: 45px;
			line-height: 52px;
		}
	}

	@media(min-width: 768px) {
		padding: 80px 55px;
		
		&--pad-smaller {
			padding: 45px 55px;
		}
		
		&__bg,
		&__bg-catalog,
		&__bg-sell {
			min-height: 470px;
		}

		&--pad-narrow {
			padding: 50px 55px;
		}

		&__title--smaller {
			font-size: 58px;
			line-height: 65px;
		}
	}

	@media(min-width: 992px) {
		padding: 100px 140px 80px 55px;
		
		&--pad-smaller {
			padding-right: 90px;
		}

		&__bg,
		&__bg-catalog,
		&__bg-sell {
			min-height: 500px;
		}

		&--pad-narrow {
			padding: 50px 55px;
		}

		&__title--smaller {
			font-size: 65px;
			line-height: 65px;
		}
	}

	@media(min-width: 1200px) {
		padding: 100px 345px 80px 55px;
		
		&--pad-smaller {
			padding-right: 200px;
		}

		&--pad-narrow {
			padding: 70px 55px;
		}
	}
}