// Margin-right
.mr-10 {
	margin-right: 10px;
}
.mr-45 {
	
	@media(min-width: 400px) {
		margin-right: 15px;
	}
	
	@media(min-width: 768px) {
		margin-right: 45px;
	}
}

.mr-80 {
	margin-right: 80px;
}

// margin-top
.mt-10 {
	margin-top: 10px;
}
.mt-15 {
	margin-top: 15px;
}

.mt-25 {
	margin-top: 25px;
	
	@media(min-width: 1200px) {
		margin-top: 0;
	}
}
.mt-30 {
	margin-top: 30px;
}

.mt-35 {
	margin-top: 35px;
}
.mt-40 {
	margin-top: 30px;
	
	@media(min-width: 768px) {
		margin-top: 40px;
	}
}
.mt-45 {
	margin-top: 35px;
	
	@media(min-width: 1200px) {
		margin-top: 45px;
	}
}
.mt-50 {
	margin-top: 50px;
}
.mt-55 {
	margin-top: 30px;
	
	@media(min-width: 768px) {
		margin-top: 55px;
	}
}
.mt-60 {
	margin-top: 30px;
	
	@media(min-width: 1200px) {
		margin-top: 60px;
	}
}
.mt-65 {
	margin-top: 40px;
	
	@media(min-width: 992px) {
		margin-top: 65px;
	}
}
.mt-70 {
	margin-top: 40px;
	
	@media(min-width: 992px) {
		margin-top: 70px;
	}
}

.mt-75 {
	margin-top: 75px;
}
.mt-80 {
	margin-top: 50px;
	
	@media(min-width: 1200px) {
		margin-top: 80px;
	}
}
.mt-82 {
	margin-top: 80px;
}
.mt-85 {
	margin-top: 50px;
	
	@media(min-width: 992px) {
		margin-top: 85px;
	}
}
.mt-90 {
	margin-top: 40px;
	
	@media(min-width: 576px) {
		margin-top: 60px;
	}
	
	@media(min-width: 1200px) {
		margin-top: 90px;
	}
}

.mt-94 {
	margin-top: 65px;
	
	@media(min-width: 1200px) {
		margin-top: 94px;
	}
}

.mt-95 {
	
	@media(min-width: 1200px) {
		margin-top: 95px;
	}
}

.mt-100 {
	margin-top: 60px;
	
	@media(min-width: 1200px) {
		margin-top: 100px;
	}
}

.mt-110 {
	margin-top: 70px;
	
	@media(min-width: 992px) {
		margin-top: 110px;
	}
}

.mt-130 {
	margin-top: 60px;
	
	@media(min-width: 576px) {
		margin-top: 80px;
	}
	
	@media(min-width: 992px) {
		margin-top: 130px;
	}
}

.mt-150 {
	margin-top: 60px;
	
	@media(min-width: 576px) {
		margin-top: 95px;
	}
	
	@media(min-width: 992px) {
		margin-top: 130px;
	}
	
	@media(min-width: 1200px) {
		margin-top: 150px;
	}
}

.mt-160 {
	margin-top: 90px;
	
	@media(min-width: 992px) {
		margin-top: 160px;
	}
}

.mt-170 {
	margin-top: 70px;
	
	@media(min-width: 768px) {
		margin-top: 110px;
	}
	
	@media(min-width: 1200px) {
		margin-top: 170px;
	}
}

// margin-bottom
.mb-30 {
	margin-bottom: 30px;
}
.mb-35 {
	margin-bottom: 35px;
}
.mb-50 {
	margin-bottom: 50px;
}
.mb-70 {
	margin-bottom: 70px;
}

// padding-top
.pt-15 {
	padding-top: 15px;
}
.pt-40 {
	padding-top: 35px;
	
	@media(min-width: 992px) {
		padding-top: 40px;
	}
}

.pt-75 {
	padding-top: 50px;
	
	@media(min-width: 992px) {
		padding-top: 75px;
	}
}
.pt-90 {
	padding-top: 60px;
	
	@media(min-width: 576px) {
		padding-top: 90px;
	}
}
.pt-110 {
	padding-top: 55px;
	
	@media(min-width: 576px) {
		padding-top: 70px;
	}
	
	@media(min-width: 992px) {
		padding-top: 110px;
	}
}
.pt-120 {
	padding-top: 80px;
	
	@media(min-width: 992px) {
		padding-top: 120px;
	}
}
.pt-130 {
	padding-top: 55px;
	
	@media(min-width: 5762px) {
		padding-top: 80px;
	}
	
	@media(min-width: 992px) {
		padding-top: 130px;
	}
}
.pt-140 {
	padding-top: 90px;
	
	@media(min-width: 992px) {
		padding-top: 140px;
	}
}
.pt-155 {
	padding-top: 55px;
	
	@media(min-width: 576px) {
		padding-top: 75px;
	}
	
	@media(min-width: 992px) {
		padding-top: 110px;
	}

	@media(min-width: 1200px) {
		padding-top: 155px;
	}
}
.pt-160 {
	padding-top: 70px;
	
	@media(min-width: 768px) {
		padding-top: 100px;
	}
	
	@media(min-width: 1200px) {
		padding-top: 160px;
	}
}

// padding-bottom
.pb-15 {
	padding-bottom: 15px;
}
.pb-45 {
	padding-bottom: 35px;
	
	@media(min-width: 992px) {
		padding-bottom: 45px;
	}
}
.pb-80 {
	padding-bottom: 80px;
	
	@media(min-width: 576px) {
		padding-bottom: 35px;
	}
	
	@media(min-width: 768px) {
		padding-bottom: 80px;
	}
}

.pb-90 {
	padding-bottom: 90px;
}

.pb-110 {
	padding-bottom: 60px;
	
	@media(min-width: 576px) {
		padding-bottom: 110px;
	}
}
.pb-120 {
	padding-bottom: 50px;
	
	@media(min-width: 576px) {
		padding-bottom: 75px;
	}
	
	@media(min-width: 992px) {
		padding-bottom: 120px;
	}
}
.pb-130 {
	padding-bottom: 80px;
	
	@media(min-width: 992px) {
		padding-bottom: 130px;
	}
}
.pb-140 {
	padding-bottom: 80px;
	
	@media(min-width: 576px) {
		padding-bottom: 100px;
	}
	
	@media(min-width: 992px) {
		padding-bottom: 140px;
	}
}
.pb-160 {
	padding-bottom: 60px;
	
	@media(min-width: 576px) {
		padding-bottom: 80px;
	}

	@media(min-width: 768px) {
		padding-bottom: 110px;
	}
	
	@media(min-width: 992px) {
		padding-bottom: 160px;
	}
}
.pb-170 {
	padding-bottom: 70px;
	
	@media(min-width: 768px) {
		padding-bottom: 110px;
	}
	
	@media(min-width: 1200px) {
		padding-bottom: 170px;
	}
}
.pb-180 {
	padding-bottom: 110px;
	
	@media(min-width: 992px) {
		padding-bottom: 180px;
	}
}
.pb-195 {
	padding-bottom: 65px;
	
	@media(min-width: 576px) {
		padding-bottom: 110px;
	}
	
	@media(min-width: 992px) {
		padding-bottom: 155px;
	}
	
	@media(min-width: 1200px) {
		padding-bottom: 195px;
	}
}

.pb-210 {
	padding-bottom: 60px;
	
	@media(min-width: 576px) {
		padding-bottom: 130px;
	}
	@media(min-width: 992px) {
		padding-bottom: 210px;
	}
}

.pb-280 {
	padding-bottom: 80px;
	
	@media(min-width: 576px) {
		padding-bottom: 120px;
	}
	
	@media(min-width: 768px) {
		padding-bottom: 180px;
	}
	
	@media(min-width: 992px) {
		padding-bottom: 280px;
	}
}

.block-margins {
	&:nth-of-type(n+2) {
		margin-top: 25px;
	}

	@media(min-width: 768px) {
		&:nth-of-type(n+2) {
			margin-top: 0;
		}

		&:nth-of-type(n+3) {
			margin-top: 25px;
		}
	}
}
.block-margins1 {
	&:nth-of-type(n+2) {
		margin-top: 30px;
	}

	@media(min-width: 768px) {
		&:nth-of-type(n+2) {
			margin-top: 0;
		}
		&:nth-of-type(n+3) {
			margin-top: 30px;
		}
	}

	@media(min-width: 992px) {
		&:nth-of-type(n+3) {
			margin-top: 0;
		}
	}
}

.block-margins2 {
	&:nth-of-type(n+2) {
		margin-top: 30px;
	}

	@media(min-width: 576px) {

		&:nth-of-type(n+2) {
			margin-top: 0;
		}

		&:nth-of-type(n+3) {
			margin-top: 30px;
		}
	}

	@media(min-width: 992px) {
		&:nth-of-type(n+3) {
			margin-top: 0;
		}
		&:nth-of-type(n+4) {
			margin-top: 30px;
		}
	}

	@media(min-width: 1200px) {

		&:nth-of-type(n+4) {
			margin-top: 0;
		}
		&:nth-of-type(n+5) {
			margin-top: 30px;
		}
	}
}

.block-margins3 {
	&:nth-of-type(n+2) {
		margin-top: 30px;
	}

	@media(min-width: 768px) {
		&:nth-of-type(n+2) {
			margin-top: 0;
		}

		&:nth-of-type(n+3) {
			margin-top: 30px;
		}
	}
}