.card-product {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	
	&:hover,
	&:focus,
	&:active {
		.card-product__bg,
		.card-product__container {
			opacity: 1;
		}
	}
	
	&__container {
		position: relative;
		z-index: 2;
		padding: 25px 25px 35px;
		opacity: 0;
		transition: all 0.4s ease-out;
		
		@media(min-width: 768px) {
			padding: 40px 25px 35px;
		}
		
		@media(min-width: 992px) {
			padding: 40px 25px 70px;
		}
	}

	&__bg {
		background: rgba(237, 29, 127, 0.7);
		opacity: 0;
		transition: all 0.4s ease-out;
	}
	
	&__img,
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 1;
	}

	&__collection {
		
	}

	&__article {
		display: inline-block;
		font-size: 23px;
		line-height: 32px;
		margin-top: 35px;
	}

	&__list {
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	&__list-item {
		font-size: 18px;
		line-height: 32px;

		span {
			font-family: 'Lato-black';
			font-weight: 900;
		}
	}

	&__price {
		display: inline-block;
		font-size: 18px;
		margin-top: 50px;
		
		span {
			font-size: 32px;
		}

		@media(min-width: 992px) {
			margin-top: 80px;
		}
	}
}