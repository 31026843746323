.logo {
	display: flex;
	height: 100%;
	
	&__title {
		font-family: 'Montserrat', sans-serif;
		font-size: 38px;
		font-weight: 900;
		line-height: 0.8;
		letter-spacing: 1.05px;
		
		@media(min-width: 576px) {
			font-size: 42px;
		}
	}

	&__subtitle {
		font-family: 'Lato-regular', sans-serif;
		font-size: 11px;
		line-height: 1;
		
		@media(min-width: 576px) {
			font-size: 13px;
			letter-spacing: 0.33px;
		}
	}

	&--pos-bottom {
		align-items: flex-end;
	}

	&--pos-center {
		align-items: center;
	}

	&--size {
		display: inline-block;
		width: 100px;
		height: 100%;
		
		@media(min-width: 576px) {
			width: 130px;
		}
		
		@media(min-width: 768px) {
			width: 145px;
		}
		
		@media(min-width: 992px) {
			width: 165px;
		}
	}
}