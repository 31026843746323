.card-collection {
	display: flex;
	align-items: flex-end;
	position: relative;
	text-decoration: none;
	background: rgba(21, 21, 26, 0.32);
	
	&--size {
		width: 100%;
		height: 200px;
		padding: 25px 25px;
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		
	}

	&__title {
		position: relative;
		display: flex;
		flex-direction: column;
		font-family: 'Raleway-extra-bold';
		font-weight: 600;
		font-size: 20px;
		letter-spacing: 1.09px;
		text-shadow: 6px 6px 29px rgba(35, 38, 47, 0.6), 0 5px 8px rgba(8, 10, 13, 0.7);
		
		span {
			font-family: 'Raleway-black';			
			font-size: 30px;
			font-weight: 900;
			letter-spacing: 2.28px;
			text-transform: uppercase;
		}

		@media(min-width: 440px) {
			span {
				font-size: 37px;
			}
		}

		@media(min-width: 992px) {
			span {
				font-size: 42px;
			}
		}
	}

}