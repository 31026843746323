.collections__container {
	width: 100%;
}

.collections__item {
	text-decoration: none;
	img {
			width: 100%;
		}

	@media(min-width: 370px) {
		img {
			width: 90%;
		}
	}
}