.catalog-filter {
	label {
		margin-bottom: 0;
	}

	input[type="checkbox"]:not(:checked),
	input[type="checkbox"]:checked {
		display: none;
	}

	input[type="checkbox"]:not(:checked) + label,
	input[type="checkbox"]:checked  + label {
		position: relative;
		cursor: pointer;
		// padding-left: 1.4em;
		line-height: 1;
		
		span {
			color: #ED1D7F;
		}
	}

	input[type="checkbox"]:not(:checked) + label:before,
	input[type="checkbox"]:checked  + label:before {
		content: "";
		position: absolute;
		right: -1.5em;
		bottom: 0;
		width: 1em;
		height: 1em;
		border: 1px solid #000000;
	}

	input[type="checkbox"]:not(:checked) + label:after,
	input[type="checkbox"]:checked  + label:after {
		content: "✔";
		position: absolute;
		right: -1.45em;
		bottom: 0;
		font-size: 1em;
		line-height: 1;
		color: #ed1d7f;
		transition: all 0.2s linear;
	}

	input[type="checkbox"]:not(:checked) + label:after {
		opacity: 0;
		transform: scale(0);
	}

	input[type="checkbox"]:checked  + label:after {
		opacity: 1;
		transform: scale(1);
	}

	input[type="radio"] {
		display: none;
	}

	input[type="radio"] + label {
		margin-left: 30px;
	}

	input[type="radio"]:checked + label {
		position: relative;
		font-family: 'Raleway-extra-bold';
		font-weight: 700;
		
		&:before {
			content: "";
			position: absolute;
			left: 0;
			bottom: -4px;
			width: 100%;
			height: 4px;
			background: #ed1d7f;
		}
	}
}

.catalog-filter__container,
.catalog-filter__wrap-dropdown {
	display: flex;
	flex-wrap: wrap;
}

.catalog-filter__wrap-dropdown {
	width: 100%;
	flex-direction: column;
	
	@media(min-width: 576px) {
		width: auto;
		flex-direction: row;
	}
}


.catalog-filter__container--align {
	justify-content: space-between;
	align-items: center;
	
	@media(min-width: 576px) {
		flex-direction: row;
		width: 50%;
	}
	
	@media(min-width: 768px) {
		width: 70%;
	}
	
	@media(min-width: 1200px) {
		width: 100%;
	}
}

.catalog-filter__list {
	position: absolute;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 20px 25px;
	width: 100%;
	box-shadow: 0 13px 50px rgba(35, 49, 74, 0.17), 0 7px 25px rgba(35, 49, 74, 0.1), 0 3px 5px rgba(35, 49, 74, 0.2);
	background-color: #ffffff;
	z-index: 2;
	
	input[type="checkbox"]:not(:checked) + label:before,
	input[type="checkbox"]:checked  + label:before {
		left: -1.5em;
	}

	input[type="checkbox"]:not(:checked) + label:after,
	input[type="checkbox"]:checked  + label:after {
		right: auto;
		left: -1.45em;
	}

	.catalog-filter__item {
		padding-left: 25px;
		width: 100%;
		line-height: 50px;
	}

	@media(min-width: 470px) {
		padding: 25px;
		width: 430px;
		
		.catalog-filter__item {
			padding-left: 35px;
			width: 45%;
			line-height: 70px;
		}
	}

	@media(min-width: 992px) {
		padding: 40px;
		width: 527px
	}
}

.catalog-filter__item {
	font-family: 'Raleway-medium';
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0.57px;
	
	@media(min-width: 992px) {
		font-size: 23px;
	}
}

.catalog-filter__list-wrap {
	display: flex;
	flex-direction: column;
}

.catalog-filter__dropdown {
	position: relative;
	
	&>input[type="checkbox"],
	.catalog-filter__list {
		display: none;
	}

	&>input[type="checkbox"]:checked + label {
		background-color: #ed1d7f;
		color: #FFF;
		svg {
			stroke: #fff;
			transform: rotate(180deg);
		}
	}

	&>input[type="checkbox"]:checked ~ .catalog-filter__list {
		display: flex;
	}
}