.card-comments {

}

.card-comments__img-slider {
	img {
		width: 100%;
	}

	@media(min-width: 470px) {
		img {
			width: 90%;
		}
	}
}

.card-comments__author {
	
}

.card-comments__product {
	font-style: italic;
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	
	@media(min-width: 576px) {
		font-size: 21px;
		line-height: 30px;
	}
	
	@media(min-width: 768px) {
		font-size: 23px;
		line-height: 32px;
	}
}

.card-comments__text {
	font-size: 15px;
	line-height: 22px;
	font-weight: 500;
	
	@media(min-width: 576px) {
		font-size: 16px;
		line-height: 26px;
	}
	
	@media(min-width: 768px) {
		font-size: 18px;
	}
}