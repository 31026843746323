.map {
	position: relative;
	width: 100%;
	height: 340px;
	
	img,
	iframe {
		position: absolute;	
		width: 110%;
		height: 100%;	
		top: 0;
		left: -5%;
	}

	@media(min-width: 576px) {
		height: 420px;
		img,
		iframe {
			width: 148%;
			height: 100%;
			left: -25%;
		}
	}

	@media(min-width: 768px) {
		height: 535px;
	}

	@media(min-width: 992px) {
		height: 630px;
		
		img,
		iframe {
			width: 220%;
			max-width: 220%;
			height: 100%;
			left: auto;
			right: -30px;
		}
	}

	@media(min-width: 1200px) {
		height: 675px;
	}
}

.map__shop-name {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
}

.map__contacts {
	position: relative;
	z-index: 2;
	padding: 65px 0 60px 0;
	
	p {
		margin-top: 25px;
		margin-bottom: 0;
	}

	p:first-of-type {
		margin-top: 45px;
	}

	@media(min-width: 768px) {
		p {
			margin-top: 35px;
		}
	}

	@media(min-width: 992px) {
		padding: 65px 0 60px 65px;
	}

	@media(min-width: 992px) {
		padding: 120px 0 60px 65px;
	}
}
.map__phone,
.map__email,
.map__address {
	font-size: 18px;
	font-weight: 500;
	
	@media(min-width: 495px) {
		font-size: 20px;
	}
	@media(min-width: 768px) {
		font-size: 23px;
	}
}

.map--bg {
	position: absolute;
	top: 0;
	left: -5%;
	width: 110%;
	height: 100%;
	background: #FFF;
	z-index: 1;
	
	@media(min-width: 576px) {
		left: -50%;
		width: 200%;
	}
	
	@media(min-width: 992px) {
		left: 0;
		width: 220%;
	}
}