.img-presentation {
	display: grid;
	grid-template-rows: auto;
	grid-template-areas:
		"first"
		"second"
		"third";
	grid-column-gap: 15px;
	grid-row-gap: 15px;
		
	
	&__item {
		&:first-of-type,
		&:nth-of-type(2),
		&:last-of-type {
			width: 100%;
			object-fit: cover;
		}
		&:nth-of-type(2),
		&:last-of-type {
			min-height: 100%;
		}

		&:first-of-type {
			grid-area: first;			
						
		}
		&:nth-of-type(2) {
			grid-area: second;
		}
		&:last-of-type {
			grid-area: third;
		}
	}
	@media(min-width: 400px) {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			"first first"
			"second third";
	}

	@media(min-width: 768px) {
		grid-template-columns: 2fr 1fr;
		grid-template-areas:
			"first second"
			"first third";
	}
}

.img-bg {
	position: absolute;
	width: 70%;
    bottom: 0;
    right: -30%;
    
    @media(min-width: 768px) {
    	bottom: -2%;
    	right: -20%;
    }
    
    @media(min-width: 992px) {
    	bottom: 0;
    	right: -35%;
    }
    
    @media(min-width: 1200px) {
    	right: -41%;
    	bottom: 6%;
    }
}