@font-face {
    font-family: 'Lato-light';
    src: url('../fonts/Lato/Lato-Light.eot');
    src: url('../fonts/Lato/Lato-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato/Lato-Light.woff') format('woff'),
    url('../fonts/Lato/Lato-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-regular';
    src: url('../fonts/Lato/Lato-Regular.eot');
    src: url('../fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato/Lato-Regular.woff') format('woff'),
    url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-medium';
    src: url('../fonts/Lato/Lato-Medium.eot');
    src: url('../fonts/Lato/Lato-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato/Lato-Medium.woff') format('woff'),
    url('../fonts/Lato/Lato-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-bold';
    src: url('../fonts/Lato/Lato-Bold.eot');
    src: url('../fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato/Lato-Bold.woff') format('woff'),
    url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-black';
    src: url('../fonts/Lato/Lato-Black.eot');
    src: url('../fonts/Lato/Lato-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lato/Lato-Black.woff') format('woff'),
    url('../fonts/Lato/Lato-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Raleway-medium';
    src: url('../fonts/Raleway/Raleway-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway-extra-bold';
    src: url('../fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway-black';
    src: url('../fonts/Raleway/Raleway-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}