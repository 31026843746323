.slider {

	&__controls {
		display: flex;
		flex-wrap: wrap;
		
		@media(min-width: 576px) {
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
		}
	}
	
	&__buttons {
		display: flex;
		width: 100%;
		justify-content: space-between;
		
		div {
			cursor: pointer;
		}

		@media(min-width: 576px) {
			width: auto;
			justify-content: flex-start;
		}
	}

	&__pagination {
		display: flex;
		font-size: 18px;
		font-weight: 500;
		line-height: 18px;
		width: 100%;
		justify-content: center;		
		margin-top: 25px;
		
		span:nth-of-type(2) {
			margin-left: 7px;
			margin-right: 7px;
		}

		@media(min-width: 576px) {
			width: auto;
			font-size: 20px;
			line-height: 20px;
			margin-top: 0;
			justify-content: flex-end;
		}

		@media(min-width: 992px) {
			font-size: 23px;
			line-height: 32px;
		}
	}
}

.slick-dots {
	list-style-type: none;
	padding-left: 0;
	display: flex;
	justify-content: center;
	margin-top: 20px;
	
	.slick-active {
		button {
			width: 10px;
			height: 10px;
			background: rgba(21, 21, 26, 0.8);
		}
	}
	
	li {
		&:not(:last-of-type) {
			margin-right: 15px;
		}
	}

	button {
		font-size: 0;
		width: 8px;
		height: 8px;
		background: rgba(21, 21, 26, 0.6);
		border: none;
		padding: 0;
	}
}